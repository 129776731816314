import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import MendHeader from './MendHeader';

import { loadProperties } from '../modules/manager';

import { toTitleCase } from '../utils/fomatter';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

import './Common.css';
import './FileUpload.css';

library.add(faBuilding);

const propertyFormatter = (cell, row) => {
  const address = `${row.house} ${toTitleCase(row.street)}, ${toTitleCase(
    row.city
  )}, ${row.state}`;
  return (
    <Link
      to={`/properties/${row.id}&unit=${row.unit || ''}`}
      style={{ textDecoration: 'none', color: '#3b4e81' }}
    >
      <div className="cell property">
        <div className="cell-v-wide">
          <div className="cell-header">Address</div>
          <div className="cell-data">{address}</div>
        </div>
        <div className="cell-v-narrow">
          <div className="cell-header">Unit</div>
          <div className="cell-data">{row.unit || '-'}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Tenant</div>
          <div className="cell-data">{row.tenant || '-'}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Owner</div>
          <div className="cell-data">{row.owner || '-'}</div>
        </div>
      </div>
    </Link>
  );
};

const AddProperty = ({ history }) => (
  <div className="file-upload" onClick={() => history.push('/properties/new')}>
    <div className="file-selector">
      <label htmlFor="file-selector" className="upload-label">
        <div className="upload-label-div">
          <div style={{ marginRight: '8px' }}>
            <FontAwesomeIcon icon="building" />
          </div>
          Add Property
        </div>
      </label>
    </div>
  </div>
);

const PropertiesList = ({ properties, options, history }) => (
  <div className="content-with-table">
    <div className="row2">
      <div className="title">Properties</div>
      <AddProperty history={history} />
    </div>
    {properties && properties.length ? (
      <div className="with-overflow">
        <BootstrapTable
          data={properties}
          pagination
          options={options}
          bordered={false}
          trClassName="tr-class"
          className="table-class"
          tableHeaderClass="tr-class"
          tableBodyClass="tr-class"
        >
          <TableHeaderColumn
            className="col-hidden"
            columnClassName="td-class"
            hidden={true}
            dataField="id"
            isKey
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            className="col-hidden"
            columnClassName="td-class"
            dataFormat={propertyFormatter}
            formatExtraData={'Name'}
            dataField="name"
          >
            Name
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    ) : (
      <div>No data</div>
    )}
  </div>
);

class Properties extends Component {
  componentWillMount() {
    if (!this.props.properties) {
      this.props.loadProperties();
    }
  }

  render() {
    const options = {
      noDataText: 'No data',
      // Pager options
      hideSizePerPage: true,
      pageStartIndex: 1,
      paginationSize: 0,
      sizePerPage: 6,
      prePage: '<',
      nextPage: '>'
    };

    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <PropertiesList
          properties={this.props.properties}
          options={options}
          history={this.props.history}
        />
      </div>
    );
  }
}

export default connect(
  ({ manager, user }) => ({
    properties: manager['properties'],
    user: user
  }),
  {
    loadProperties
  }
)(Properties);
