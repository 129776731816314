import React, { Component } from 'react';
import { connect } from 'react-redux';

import { login, logout } from '../modules/user';

import MendHeader from './MendHeader';

import './Common.css';
import './Login.css';

class Login extends Component {
  state = {
    username: '',
    password: ''
  };

  // If we already logged in, just redirect
  componentWillMount() {
    if (this.props.user.session) {
      this.props.logout();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user } = nextProps;
    if (user.session) {
      this.props.history.replace('/');
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    await this.props.login(this.state);
  }

  render() {
    return (
      <div className="bG">
        <MendHeader history={this.props.history} />
        <div className="content">
          <div className="login">Log In</div>
          <div className="on-demandrentalmai">ON-DEMAND RENTAL MAINTENANCE</div>
          <form onSubmit={this.handleFormSubmit.bind(this)}>
            <div className="field-login-form">
              <label htmlFor="username">Username</label>
              <input
                className="form-item"
                placeholder="E-mail or phone"
                name="username"
                type="text"
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-login-form">
              <label htmlFor="password">Password</label>
              <input
                className="form-item"
                placeholder="Password"
                name="password"
                type="password"
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <button className="form-submit" type="submit">
              Log In
            </button>
          </form>
          <div className="forgotyourpassword">Forgot your password?</div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user }) => ({ user }),
  { login, logout }
)(Login);
