import * as API from '../utils/api';

const { get, post } = API;

// Action creators
const requestUser = session => ({
  type: 'REQUEST_USER',
  session
});

const receiveUser = response => ({
  type: 'RECEIVE_USER',
  payload: response,
  meta: {}
});

const receiveUserError = err => ({
  type: 'RECEIVE_USER_ERROR',
  error: true,
  payload: err
});

const logoutUser = session => ({
  type: 'LOGOUT_USER',
  session
});

export const logout = () => async (dispatch, getState) => {
  const session = getState().user.session;
  if (!session) {
    dispatch(logoutUser());
    return;
  }
  try {
    await get(session, `/logout`);
    dispatch(logoutUser());
  } catch (err) {
    console.log(err);
  }
};

export const login = ({ username, password }) => async (dispatch, getState) => {
  const session = getState().user.session;
  dispatch(requestUser(session));

  try {
    const response = await post(session, `/user/login`, { username, password });
    const data = await response.json();
    data.username = username;
    dispatch(receiveUser(data));
  } catch (err) {
    dispatch(receiveUserError(err));
  }
};

const initialState = {
  session: JSON.parse(localStorage.getItem('themend')),
  loading: false,
  loaded: false,
  error: false,
  data: null,
  meta: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_USER': {
      return Object.assign({}, state, {
        loading: true,
        error: false,
        session: action.session || null
      });
    }
    case 'RECEIVE_USER': {
      const newState = Object.assign({}, state, {
        loading: false,
        loaded: true,
        error: false,
        session: action.payload || null
      });
      if (localStorage.getItem('themend') !== JSON.stringify(action.payload)) {
        localStorage.setItem('themend', JSON.stringify(action.payload));
      }
      return newState;
    }
    case 'RECEIVE_USER_ERROR': {
      return Object.assign({}, state, {
        loading: false,
        error: true,
        data: action.payload.message
      });
    }
    case 'LOGOUT_USER': {
      if (localStorage.getItem('themend')) {
        localStorage.removeItem('themend');
      }
      return Object.assign({}, state, {
        loading: true,
        loaded: true,
        error: false,
        session: null
      });
    }

    default:
      return state;
  }
};
