import React, { Component } from 'react';
import { connect } from 'react-redux';

import MendHeader from './MendHeader';
import OwnerList from './OwnerList';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import './Common.css';

library.add(faUser);

const AddOwner = ({ history }) => (
  <div
    style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: '10px' }}
    onClick={() => history.push('/owners/new')}
  >
    <div className="file-selector">
      <label htmlFor="file-selector" className="upload-label">
        <div className="upload-label-div">
          <div style={{ marginRight: '8px' }}>
            <FontAwesomeIcon icon="user" />
          </div>
          Add Owner
        </div>
      </label>
    </div>
  </div>
);

class Owners extends Component {
  render() {
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content-with-table">
          <div className="row2">
            <div className="title">Owners</div>
            <AddOwner history={this.props.history} />
          </div>
          <OwnerList />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user }) => ({ user }),
  {}
)(Owners);
