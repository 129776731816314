import React, { Component } from 'react';
import './FileUpload.css';
import uploadImg from '../assets/upload.png';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    // User cancelled
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.props.handler(file);
  }

  render() {
    return (
      <div className="file-upload">
        <form className="file-upload-form" onSubmit={this.onFormSubmit}>
          <div className="file-selector">
            <input id="file-selector" type="file" onChange={this.onChange} />
            <label htmlFor="file-selector" className="upload-label">
              <div className="upload-label-div">
                <img src={uploadImg} alt="Upload button" />
                Upload list
              </div>
            </label>
          </div>
        </form>
      </div>
    );
  }
}

export default FileUpload;
