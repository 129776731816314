import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadOwners, loadProperties, saveOwner } from '../modules/manager';

import MendHeader from './MendHeader';
import { FormErrors } from './FormErrors';

import states from './states.json';

class AddOwner extends Component {
  state = {
    username: '',
    name: '',
    email: '',
    street: '',
    city: '',
    state: 'AL',
    zipcode: '',
    house: '',
    propertyId: undefined,
    unit: null,
    formErrors: {
      email: '',
      zipcode: '',
      state: '',
      city: '',
      street: '',
      name: '',
      unit: '',
      house: '',
      propertyId: ''
    },
    formValid: false
  };

  componentWillMount() {
    if (!this.props.properties) {
      this.props.loadProperties();
    }
    if (!this.props.owners) {
      this.props.loadOwners();
    } else {
      const id = parseInt(this.props.match.params.ownerId) || '';
      const owner = Object.values(this.props.owners).filter(
        entry => entry.id === id
      )[0];
      console.log('owner:', owner);
      owner &&
        this.setState({
          zipcode: owner['zipcode'],
          state: owner['state'],
          city: owner['city'],
          street: owner['street'],
          house: owner['house'],
          name: owner['name'],
          email: owner['email'],
          unit: owner['unit'],
          username: owner['username'],
          propertyId: `${owner['property_id']}-${owner['unit'] || ''}`,
          zipcodeValid: true,
          streetValid: true,
          cityValid: true,
          houseValid: true,
          formValid: true
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    const id = parseInt(this.props.match.params.ownerId) || '';
    console.log('id:', id);
    if (id !== '' && !this.props.owners && nextProps.owners) {
      const owner = Object.values(nextProps.owners).filter(
        entry => entry.id === id
      )[0];
      console.log('owner:', owner);
      this.setState({
        zipcode: owner['zipcode'],
        state: owner['state'],
        city: owner['city'],
        street: owner['street'],
        house: owner['house'],
        name: owner['name'],
        email: owner['email'],
        unit: owner['unit'],
        username: owner['username'],
        propertyId: `${owner['property_id']}-${owner['unit'] || ''}`,
        zipcodeValid: true,
        streetValid: true,
        cityValid: true,
        houseValid: true,
        formValid: true
      });
    }
    if (nextProps.saveSuccess) {
      // saved a new property
      this.props.history.push('/owners');
    }
  }

  errorClass(error) {
    return !error.length ? '' : '-has-error';
  }

  validateFieldAndForm(fieldName, value) {
    const validFields = this.validateField(fieldName, value);
    this.setState(validFields, this.validateForm);
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let zipcodeValid = this.state.zipcodeValid;
    let cityValid = this.state.cityValid;
    let streetValid = this.state.streetValid;
    let houseValid = this.state.houseValid;

    switch (fieldName) {
      case 'zipcode':
        zipcodeValid = /^\d{5}([\s-]\d{4})?$/.test(value);
        fieldValidationErrors.zipcode = zipcodeValid ? '' : 'Invalid ZIP code';
        break;
      case 'city':
        cityValid = value.match(/^([\w].+)$/i);
        fieldValidationErrors.city = cityValid
          ? ''
          : 'City must begin with a letter';
        break;
      case 'street':
        streetValid = /^([\w].+)$/.test(value);
        fieldValidationErrors.street = streetValid
          ? ''
          : 'Street must begin with a letter';
        break;
      case 'house':
        houseValid = /^([\d].*)$/i.test(value);
        fieldValidationErrors.house = houseValid
          ? ''
          : 'House must begin with a digit';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        zipcodeValid: zipcodeValid,
        cityValid: cityValid,
        streetValid: streetValid,
        houseValid: houseValid
      },
      () => {
        this.validateForm();
      }
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.zipcodeValid &&
        this.state.cityValid &&
        this.state.streetValid &&
        this.state.houseValid &&
        this.state.propertyId
    });
  }

  handleChange(event) {
    const propertyId = this.state.propertyId;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
        unit: propertyId
          ? propertyId.substring(propertyId.indexOf('-') + 1)
          : undefined
      },
      () => {
        this.validateFieldAndForm(name, value);
      }
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    let owner = {
      name: this.state.name,
      email: this.state.email,
      house: this.state.house,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      propertyId: this.state.propertyId.substring(
        0,
        this.state.propertyId.indexOf('-')
      ),
      unit: this.state.unit
    };
    const id = this.props.match.params.id;
    if (id && id !== '') {
      owner['id'] = id;
    }
    console.log('saving owner:', owner);
    this.props.saveOwner(owner);
  }

  render() {
    console.log('props:', this.props);
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content">
          <div className="profile">{}</div>
          <div className="error">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          <form>
            {!parseInt(this.props.match.params.ownerId) && (
              <div className="field-profile-form">
                <label htmlFor="state">Property</label>
                <select
                  name="propertyId"
                  className={`form-item${this.errorClass(
                    this.state.formErrors.propertyId
                  )}`}
                  value={this.state.propertyId}
                  onChange={this.handleChange.bind(this)}
                >
                  {[{ id: 0, house: 'Property' }]
                    .concat(this.props.properties || [])
                    .map((item, i) => (
                      <option key={i} value={`${item.id}-${item.unit || ''}`}>
                        {`${
                          item.unit && item.unit !== '' ? `[${item.unit}]` : ''
                        } ${item.house} ${item.street || ''} ${item.city ||
                          ''} ${
                          item.state ? `${item.state},` : ''
                        } ${item.zipcode || ''}`}
                      </option>
                    ))}
                </select>
                <div className="rectangle" />
              </div>
            )}
            <div className="field-profile-form">
              <label htmlFor="name">Name</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.name
                )}`}
                placeholder="Full name"
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="email">Email</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.email
                )}`}
                placeholder="E-mail"
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="house">House</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.house
                )}`}
                placeholder="House"
                name="house"
                type="text"
                value={this.state.house}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="street">Street</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.street
                )}`}
                placeholder="Street"
                name="street"
                type="text"
                value={this.state.street}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="city">City</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.city
                )}`}
                placeholder="City"
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="state">State</label>
              <select
                name="state"
                className={`form-item${this.errorClass(
                  this.state.formErrors.state
                )}`}
                value={this.state.state}
                onChange={this.handleChange.bind(this)}
              >
                {states.map(item => (
                  <option key={item.abbreviation} value={item.abbreviation}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="zipcode">ZIP code</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.zipcode
                )}`}
                placeholder="ZIP code"
                name="zipcode"
                type="text"
                value={this.state.zipcode}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <button
              className="form-submit"
              type="submit"
              disabled={!this.state.formValid}
              onClick={e => this.handleFormSubmit(e)}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ manager }) => ({
    owners: manager['owners'],
    properties: manager['properties'],
    saveSuccess: manager['saveSuccess']
  }),
  {
    loadProperties,
    loadOwners,
    saveOwner
  }
)(AddOwner);
