import * as API from '../utils/api';

const { get, put } = API;

const requestTenantProfile = id => ({
  type: 'REQUEST_TENANT_PROFILE',
  meta: id
});

const receiveTenantProfile = (data, meta) => ({
  type: 'RECEIVE_TENANT_PROFILE',
  payload: data,
  meta: meta
});

const receiveTenantProfileError = err => ({
  type: 'RECEIVE_TENANT_PROFILE_ERROR',
  error: true,
  payload: err
});

const requestSaveTenantProfile = id => ({
  type: 'REQUEST_SAVE_TENANT_PROFILE',
  meta: id
});

const receiveSaveTenantProfile = () => ({
  type: 'RECEIVE_SAVE_TENANT_PROFILE'
});

const receiveSaveTenantProfileError = err => ({
  type: 'RECEIVE_SAVE_TENANT_PROFILE_ERROR',
  error: true,
  payload: err
});

export const loadProfile = id => (dispatch, getState) => {
  const url = `/tenants/${id}`;
  const session = getState().user.session;
  dispatch(requestTenantProfile(id));
  return get(session, url)
    .then(response =>
      response.json().then(data => {
        dispatch(receiveTenantProfile(data));
      })
    )
    .catch(err => dispatch(receiveTenantProfileError(err)));
};

export const saveProfile = (profile, id) => (dispatch, getState) => {
  const url = `/tenants/${id}`;
  const session = getState().user.session;
  dispatch(requestSaveTenantProfile(id));
  return put(session, url, profile)
    .then(response => {
      dispatch(receiveSaveTenantProfile(response));
      return dispatch(loadProfile(id));
    })
    .catch(err => dispatch(receiveSaveTenantProfileError(err)));
};

const initialState = {
  loading: false,
  loaded: false,
  saveSuccess: false,
  saveError: false,
  saveErrorMessage: null,
  saveInProgress: false,
  error: false,
  tenantProfile: null,
  meta: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_TENANT_PROFILE': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_TENANT_PROFILE_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_TENANT_PROFILE': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        tenantProfile: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_SAVE_TENANT_PROFILE': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_TENANT_PROFILE': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_TENANT_PROFILE_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;

      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: true,
        saveErrorMessage: serverError
      });
    }
    default:
      return state;
  }
};
