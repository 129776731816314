import React, { Component } from 'react';
//import { connect } from 'react-redux';

import MendHeader from './MendHeader';

//import termsText from '../assets/terms.rtf';
//const termsText = '../assets/terms.rtf';

/* const RTF = ({ document }) => {
  return document ? <div className="">{document}</div> : null;
}; */

class Terms extends Component {
  render() {
    // console.log('termsText:', termsText);
    return (
      <div className="bG">
        <MendHeader history={this.props.history} />
        <div className="content">
          <div style={{ textAlign: 'left' }}>
            Terms of Use
            <br />
            The Mend provides a personalized subscription service that allows
            our members to access automate rental properties maintenance
            scheduling. These Terms of Use govern your use of our service. As
            used in these Terms of Use, "The Mend service", “Mend”, "our
            service" or "the service" means the personalized service provided by
            Mendapp, Inc, including all features and functionalities, the
            website, and user interfaces, as well as all content and software
            associated with our service.
            <br />
            1. Membership
            <br />
            o 1.1. Your membership will continue month-to-month until
            terminated. To use our service you must have Internet access and a
            The Mend compatible device, and provide us with one or more Payment
            Methods. "Payment Method" means a current, valid, accepted method of
            payment, as may be updated from time to time, and which may include
            payment through your account with a third party. Unless you cancel
            your membership before your monthly billing date, you authorize us
            to charge your next month's membership fee to your Payment Method
            (see "Cancellation" below).
            <br />
            o 1.2. We may offer a number of membership plans, including special
            promotional plans or memberships offered by third parties in
            conjunction with the provision of their own products and services.
            We are not responsible for the products and services provided by
            such third parties. Some membership plans may have differing
            conditions and limitations, which will be disclosed at your sign-up
            or in other communications made available to you. You can find
            specific details regarding your Mend membership by visiting our
            website and clicking on the "Account" link available at the top of
            the pages of the Mend website under your profile name.
            <br />
            2. Free Trials
            <br />
            o 2.1. You’re the Mend membership may start with a free trial. The
            free trial period of your membership lasts for one month, or as
            otherwise specified during sign-up and is intended to allow new
            members and certain former members to try the service.
            <br />
            o 2.2. Free trial eligibility is determined by The Mend at its sole
            discretion and we may limit eligibility or duration to prevent free
            trial abuse. We reserve the right to revoke the free trial and put
            your account on hold in the event that we determine that you are not
            eligible. For combinations with other offers, restrictions may
            apply.
            <br />
            o 2.3. We will charge your Payment Method for your monthly
            membership fee at the end of the free trial period unless you cancel
            your membership prior to the end of the free trial period. To view
            the monthly membership price and end date of your free trial period,
            visit our website and click the "Billing details" link on the
            "Account" page.
            <br />
            3. Billing and Cancellation
            <br />
            o 3.1. Billing Cycle. The membership fee for the Mend service and
            any other charges you may incur in connection with your use of the
            service, such as taxes and possible transaction fees, will be
            charged on a monthly basis to your Payment Method on the calendar
            day corresponding to the commencement of the paying portion of your
            membership. In some cases your payment date may change, for example
            if your Payment Method has not successfully settled or if your paid
            membership began on a day not contained in a given month. Visit our
            website and click on the "Billing details" link on the "Account"
            page to see your next payment date. We may authorize your Payment
            Method in anticipation of membership or service-related charges
            through various methods, including authorizing it up to
            approximately one month of service as soon as you register. In some
            instances, your available balance or credit limit may be reduced to
            reflect the authorization during your free trial period.
            <br />
            o 3.2. Payment Methods. To use the Mend service you must provide one
            or more Payment Methods. You can update your Payment Methods by
            going to the "Account" page. We may also update your Payment Methods
            using information provided by the payment service providers.
            Following any update, you authorize us to continue to charge the
            applicable Payment Method(s). You authorize us to charge any Payment
            Method associated to your account in case your primary Payment
            Method is declined or no longer available to us for payment of your
            subscription fee. You remain responsible for any uncollected
            amounts. If a payment is not successfully settled, due to
            expiration, insufficient funds, or otherwise, and you do not cancel
            your account, we may suspend your access to the service until we
            have successfully charged a valid Payment Method. For some Payment
            Methods, the issuer may charge you certain fees, such as foreign
            transaction fees or other fees relating to the processing of your
            Payment Method. Local tax charges may vary depending on the Payment
            Method used. Check with your Payment Method service provider for
            details.
            <br />
            o 3.3. Cancellation. You can cancel your Mend membership at any
            time, and you will continue to have access to the Mend service
            through the end of your monthly billing period. To the extent
            permitted by the applicable law, payments are non-refundable and we
            do not provide refunds or credits for any partial-month membership
            periods. To cancel, go to the "Account" page and follow the
            instructions for cancellation. If you cancel your membership, your
            account will automatically close at the end of your current billing
            period. To see when your account will close, click "Billing details"
            on the "Account" page.
            <br />
            o 3.4. Changes to the Price and Subscription Plans. We may change
            our subscription plans and the price of our service from time to
            time; however, any price changes or changes to our subscription
            plans will apply to you no earlier than 30 days following notice to
            you.
            <br />
            4. The Mend Service
            <br />
            o 4.1. You must be 18 years of age, or the age of majority in your
            province, territory or country, to become a member of the Mend
            service. Minors may only use the service under the supervision of an
            adult.
            <br />
            o 4.2. You agree to use the Mend service, including all features and
            functionalities associated therewith, in accordance with all
            applicable laws, rules and regulations, or other restrictions on use
            of the service or content therein. We may terminate or restrict your
            use of our service if you violate these Terms of Use or are engaged
            in illegal or fraudulent use of the service.
            <br />
            o 4.3. The Mend software is developed by, or for, The Mend service
            and is designed to enable it on The Mend service ready devices. This
            software may vary by device and medium, and functionalities and
            features may also differ between devices. You acknowledge that the
            use of the service may require third party software that is subject
            to third party licenses. You agree that you may automatically
            receive updated versions of the Mend and related third-party
            software.
            <br />
            5. Passwords and Account Access. The member who created the Mend
            account and whose Payment Method is charged (the "Account Owner")
            has access and control over the Mend account and is responsible for
            any activity that occurs through the Mend account. To maintain
            control over the account and to prevent anyone from accessing the
            account (which would include information on viewing history for the
            account), the Account Owner should maintain control over the Mend
            ready devices that are used to access the service and not reveal the
            password or details of the Payment Method associated with the
            account to anyone. You are responsible for updating and maintaining
            the accuracy of the information you provide to us relating to your
            account. We can terminate your account or place your account on hold
            in order to protect you, The Mend or our partners from identity
            theft or other fraudulent activity.
            <br />
            6. Warranties and Limitations on Liability. The Mend service is
            provided "as is" and without warranty or condition. In particular,
            our service may not be uninterrupted or error-free. You waive all
            special, indirect and consequential damages against us. These terms
            will not limit any non-waivable warranties or consumer protection
            rights that you may be entitled to under the laws of your country of
            residence.
            <br />
            7. Arbitration Agreement.  7.1. If you are a Mend member in the
            United States (including its possessions and territories), you and
            Mend agree that any dispute, claim or controversy arising out of or
            relating in any way to the Mend service, these Terms of Use and this
            Arbitration Agreement, shall be determined by binding arbitration or
            in small claims court. Arbitration is more informal than a lawsuit
            in court. Arbitration uses a neutral arbitrator instead of a judge
            or jury, allows for more limited discovery than in court, and is
            subject to very limited review by courts. Arbitrators can award the
            same damages and relief that a court can award and nothing in this
            Arbitration Agreement shall be interpreted as limiting any
            non-waivable statutory rights. You agree that, by agreeing to these
            Terms of Use, the U.S. Federal Arbitration Act governs the
            interpretation and enforcement of this provision, and that you and
            Mend are each waiving the right to a trial by jury or to participate
            in a class action. This arbitration provision shall survive
            termination of this Agreement and the termination of your Mend
            membership.
            <br />
            7.2. If you elect to seek arbitration or file a small claim court
            action, you must first send to The Mend, by certified mail, a
            written Notice of your claim ("Notice"). The Notice to Mend must be
            addressed to: LAWYER ADDRESS ("Notice Address"). If The Mend
            initiates arbitration, it will send a written Notice to the email
            address used for your membership account. A Notice, whether sent by
            you or by The Mend, must (a) describe the nature and basis of the
            claim or dispute; and (b) set forth the specific relief sought
            ("Demand"). If Mend and you do not reach an agreement to resolve the
            claim within 30 days after the Notice is received, you or The Mend
            may commence an arbitration proceeding or file a claim in small
            claims court.
            <br />
            7.3. You may download or copy a form Notice and a form to initiate
            arbitration at www.adr.org. If you are required to pay a filing fee,
            after Mend receives notice at the Notice Address that you have
            commenced arbitration, The Mend will reimburse you for your payment
            of the filing fee, unless your claim is for greater than US$10,000,
            in which event you will be responsible for filing fees.
            <br />
            7.4. The arbitration will be governed by the Commercial Arbitration
            Rules (the "AAA Rules") of the American Arbitration Association
            ("AAA"), as modified by this Agreement, and will be administered by
            the AAA. The AAA Rules and Forms are available online
            at www.adr.org, by calling the AAA at 1-800-778-7879, or by writing
            to the Notice Address. The arbitrator is bound by the terms of this
            Agreement. All issues are for the arbitrator to decide, including
            issues relating to the scope and enforceability of this arbitration
            agreement. Unless Mend and you agree otherwise, any arbitration
            hearings will take place in the county (or parish) of your
            residence. The arbitrator's award shall be final and binding on all
            parties, except (1) for judicial review expressly permitted by law
            or (2) if the arbitrator's award includes an award of injunctive
            relief against a party, in which case that party shall have the
            right to seek judicial review of the injunctive relief in a court of
            competent jurisdiction that shall not be bound by the arbitrator's
            application or conclusions of law.
            <br />
            7.5. If your claim is for US$10,000 or less, we agree that you may
            choose whether the arbitration will be conducted solely on the basis
            of documents submitted to the arbitrator, through a telephonic
            hearing, or by an in-person hearing as established by the AAA Rules.
            If your claim exceeds US$10,000, the right to a hearing will be
            determined by the AAA Rules. Regardless of the manner in which the
            arbitration is conducted, the arbitrator shall issue a reasoned
            written decision explaining the essential findings and conclusions
            on which the award is based. If the arbitrator issues you an award
            that is greater than the value of Mend's last written settlement
            offer made before an arbitrator was selected (or if The Mend did not
            make a settlement offer before an arbitrator was selected), then
            Mend will pay you the amount of the award or US$5,000, whichever is
            greater. Except as expressly set forth herein, the payment of all
            filing, administration and arbitrator fees will be governed by the
            AAA Rules.
            <br />
            7.6. YOU AND MEND AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
            ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
            CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
            Further, unless both you and Mend agree otherwise, the arbitrator
            may not consolidate more than one person's claims with your claims,
            and may not otherwise preside over any form of a representative or
            class proceeding. If this specific provision is found to be
            unenforceable, then the entirety of this arbitration provision shall
            be null and void.
            <br />
            8. Miscellaneous
            <br />
            o 8.1. Governing Law. These Terms of Use shall be governed by and
            construed in accordance with the laws of the state of California,
            U.S.A. without regard to conflict of laws provisions. These terms
            will not limit any consumer protection rights that you may be
            entitled to under the mandatory laws of your state of residence.
            <br />
            o 8.2. Unsolicited Materials. The Mend does not accept unsolicited
            materials, and is not responsible for the similarity of any of its
            products in any media to materials or ideas transmitted to The Mend.
            Should you send any unsolicited materials or ideas, you do so with
            the understanding that no additional consideration of any sort will
            be provided to you, and you are waiving any claim against Mend and
            its affiliates regarding the use of such materials and ideas, even
            if material or an idea is used that is substantially similar to the
            material or idea you sent.
            <br />
            o 8.3. Feedback. The Mend is free to use any comments, information,
            ideas, concepts, reviews, or techniques or any other material
            contained in any communication you may send to us ("Feedback"),
            including responses to questionnaires or through postings to the
            Mend service, including the Mend website and user interfaces,
            worldwide and in perpetuity without further compensation,
            acknowledgement or payment to you for any purpose whatsoever
            including, but not limited to, developing, manufacturing and
            marketing products and creating, modifying or improving the Mend
            service. In addition, you agree not to enforce any "moral rights" in
            and to the Feedback, to the extent permitted by applicable law.
            <br />
            o 8.4. Customer Support. To find more information about our service
            and its features, or if you need assistance with your account,
            please visit the Mend Help Center on our website. In certain
            instances, Customer Service may best be able to assist you by using
            a remote access support tool through which we have full access to
            your computer. If you do not want us to have this access, you should
            not consent to support through the remote access tool, and we will
            assist you through other means. In the event of any conflict between
            these Terms of Use and information provided by Customer Service or
            other portions of our website, these Terms of Use will control.
            <br />
            o 8.5. Survival. If any provision or provisions of these Terms of
            Use shall be held to be invalid, illegal, or unenforceable, the
            validity, legality and enforceability of the remaining provisions
            shall remain in full force and effect.
            <br />
            o 8.6. Changes to Terms of Use and Assignment. The Mend may, from
            time to time, change these Terms of Use. Such revisions shall be
            effective immediately; provided however, for existing members, such
            revisions shall, unless otherwise stated, be effective 30 days after
            posting. We may assign our agreement with you to any affiliated
            company or to any entity that succeeds to all or substantially all
            of our business or assets related to the applicable Mend service.
            <br />
            o 8.7. Communication Preferences. We will send you information
            relating to your account (e.g. payment authorizations, invoices,
            changes in password or Payment Method, confirmation messages,
            notices) in electronic form only, for example via emails to your
            email address provided during registration. You agree that any
            notices, agreements, disclosures or other communications that we
            send to you electronically will satisfy any legal communication
            requirements, including that such communications be in writing.
            <br />
            Last updated: 30 November 2018
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
