import React, { Component } from 'react';

import MendHeader from './MendHeader';

import './Common.css';
import './Confirmation.css';

class RegisterConfirmation extends Component {
  handleFormSubmit(e) {
    e.preventDefault();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="bG">
        <MendHeader history={this.props.history} />
        <div className="content">
          <div className="confirmation">
            You have been successfully registered.
          </div>
          <form onSubmit={this.handleFormSubmit.bind(this)}>
            <button className="form-submit" value="Log In" type="submit">
              Log In
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default RegisterConfirmation;
