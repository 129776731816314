import * as API from '../utils/api';

const { get, put, post, uploadFile } = API;

export const addTenant = () => ({
  type: 'ADD_TENANT'
});

export const cancelAddTennt = formId => ({
  type: 'CANCEL_ADD_TENANT',
  meta: {
    formId
  }
});

export const editTenant = tenantId => ({
  type: 'EDIT_TENANT',
  meta: {
    tenantId
  }
});

const requestTenants = () => ({
  type: 'REQUEST_TENANTS'
});

const receiveTenants = (data, meta) => ({
  type: 'RECEIVE_TENANTS',
  payload: data,
  meta: meta
});

const receiveTenantsError = err => ({
  type: 'RECEIVE_TENANTS_ERROR',
  error: true,
  payload: err
});

const requestSaveTenant = () => ({
  type: 'REQUEST_SAVE_TENANT'
});

const receiveSaveTenant = response => ({
  type: 'RECEIVE_SAVE_TENANT',
  payload: response
});

const receiveSaveTenantError = err => ({
  type: 'RECEIVE_SAVE_TENANT_ERROR',
  error: true,
  payload: err
});

const requestDeleteTenant = () => ({
  type: 'REQUEST_DELETE_TENANT'
});

const receiveDeleteTenant = id => ({
  type: 'RECEIVE_DELETE_TENANT',
  meta: {
    id
  }
});

const receiveDeleteTenantError = err => ({
  type: 'RECEIVE_DELETE_TENANT_ERROR',
  error: true,
  payload: err
});

const requestManagerProfile = id => ({
  type: 'REQUEST_MANAGER_PROFILE',
  meta: id
});

const receiveManagerProfile = (data, meta) => ({
  type: 'RECEIVE_MANAGER_PROFILE',
  payload: data,
  meta: meta
});

const receiveManagerProfileError = err => ({
  type: 'RECEIVE_MANAGER_PROFILE_ERROR',
  error: true,
  payload: err
});

const requestSaveManagerProfile = id => ({
  type: 'REQUEST_SAVE_MANAGER_PROFILE',
  meta: id
});

const receiveSaveManagerProfile = () => ({
  type: 'RECEIVE_SAVE_MANAGER_PROFILE'
});

const receiveSaveManagerProfileError = err => ({
  type: 'RECEIVE_SAVE_MANAGER_PROFILE_ERROR',
  error: true,
  payload: err
});

const requestOwners = () => ({
  type: 'REQUEST_OWNERS'
});

const receiveOwners = (data, meta) => ({
  type: 'RECEIVE_OWNERS',
  payload: data,
  meta: meta
});

const receiveOwnersError = err => ({
  type: 'RECEIVE_OWNERS_ERROR',
  error: true,
  payload: err
});

const requestSaveOwner = () => ({
  type: 'REQUEST_SAVE_OWNER'
});

const receiveSaveOwner = response => ({
  type: 'RECEIVE_SAVE_OWNER',
  payload: response
});

const receiveSaveOwnerError = err => ({
  type: 'RECEIVE_SAVE_OWNER_ERROR',
  error: true,
  payload: err
});

const requestVendors = () => ({
  type: 'REQUEST_VENDORS'
});

const receiveVendors = (data, meta) => ({
  type: 'RECEIVE_VENDORS',
  payload: data,
  meta: meta
});

const receiveVendorsError = err => ({
  type: 'RECEIVE_VENDORS_ERROR',
  error: true,
  payload: err
});

const requestProperties = () => ({
  type: 'REQUEST_PROPERTIES'
});

const receiveProperties = (data, meta) => ({
  type: 'RECEIVE_PROPERTIES',
  payload: data,
  meta: meta
});

const receivePropertiesError = err => ({
  type: 'RECEIVE_PROPERTIES_ERROR',
  error: true,
  payload: err
});

const requestSaveProperty = () => ({
  type: 'REQUEST_SAVE_PROPERTY'
});

const receiveSaveProperty = () => ({
  type: 'RECEIVE_SAVE_PROPERTY'
});

const receiveSavePropertyError = err => ({
  type: 'RECEIVE_SAVE_PROPERTY_ERROR',
  error: true,
  payload: err
});

const requestSavePropertiesInfo = () => ({
  type: 'REQUEST_SAVE_PROPERTIES'
});

const receiveSavePropertiesInfo = response => ({
  type: 'RECEIVE_SAVE_PROPERTIES',
  payload: response
});

const receiveSavePropertiesInfoError = err => ({
  type: 'RECEIVE_SAVE_PROPERTIES_ERROR',
  error: true,
  payload: err
});

export const loadProfile = id => (dispatch, getState) => {
  const url = `/managers/${id}`;
  const session = getState().user.session;
  dispatch(requestManagerProfile(id));
  return get(session, url)
    .then(response =>
      response.json().then(data => {
        dispatch(receiveManagerProfile(data));
      })
    )
    .catch(err => dispatch(receiveManagerProfileError(err)));
};

export const saveProfile = (profile, id) => (dispatch, getState) => {
  const url = `/managers/${id}`;
  const session = getState().user.session;
  dispatch(requestSaveManagerProfile(id));
  return put(session, url, profile)
    .then(response => {
      dispatch(receiveSaveManagerProfile(response));
      return dispatch(loadProfile(id));
    })
    .catch(err => dispatch(receiveSaveManagerProfileError(err)));
};

export const loadTenants = () => async (dispatch, getState) => {
  dispatch(requestTenants());
  const url = '/tenants';
  const session = getState().user.session;
  try {
    const response = await get(session, url);
    const data = await response.json();
    dispatch(receiveTenants(data, response.meta));
  } catch (err) {
    dispatch(receiveTenantsError(err));
  }
};

export const loadOwners = () => async (dispatch, getState) => {
  dispatch(requestOwners());
  const url = '/owners';
  const session = getState().user.session;
  try {
    const response = await get(session, url);
    const data = await response.json();
    dispatch(receiveOwners(data, response.meta));
  } catch (err) {
    dispatch(receiveOwnersError(err));
  }
};

export const loadVendors = () => async (dispatch, getState) => {
  dispatch(requestVendors());
  const url = '/vendors';
  const session = getState().user.session;
  try {
    const response = await get(session, url);
    const data = await response.json();
    dispatch(receiveVendors(data, response.meta));
  } catch (err) {
    dispatch(receiveVendorsError(err));
  }
};

export const loadProperties = () => async (dispatch, getState) => {
  dispatch(requestProperties());
  const url = '/properties/full';
  const session = getState().user.session;
  try {
    const response = await get(session, url);
    const data = await response.json();
    dispatch(receiveProperties(data, response.meta));
  } catch (err) {
    dispatch(receivePropertiesError(err));
  }
};

export const saveProperty = property => async (dispatch, getState) => {
  const session = getState().user.session;
  const id = property['id'];
  const url = id && id !== '' ? `/properties/${id}` : '/properties';
  try {
    dispatch(requestSaveProperty());
    let response;
    if (id) {
      response = await put(session, url, property);
    } else {
      response = await post(session, url, property);
    }
    dispatch(receiveSaveProperty());
    return dispatch(loadProperties());
  } catch (err) {
    dispatch(receiveSavePropertyError(err));
  }
};

export const savePropertiesInfo = file => async (dispatch, getState) => {
  const session = getState().user.session;
  try {
    dispatch(requestSavePropertiesInfo());
    const response = await uploadFile(session, file);
    dispatch(receiveSavePropertiesInfo(response));
    dispatch(loadTenants());
    dispatch(loadOwners());
    dispatch(loadProperties());
    return dispatch(loadVendors());
  } catch (err) {
    dispatch(receiveSavePropertiesInfoError(err));
  }
};

export const saveTenant = tenant => async (dispatch, getState) => {
  const session = getState().user.session;
  const id = tenant['id'];
  const url = id && id !== '' ? `/tenants/${id}` : '/tenants';
  try {
    dispatch(requestSaveTenant());
    let response;
    if (id) {
      response = await put(session, url, tenant);
    } else {
      response = await post(session, url, tenant);
    }
    dispatch(receiveSaveTenant(response));
    return dispatch(loadTenants());
  } catch (err) {
    dispatch(receiveSaveTenantError(err));
  }
};

export const saveOwner = owner => async (dispatch, getState) => {
  const session = getState().user.session;
  const id = owner['id'];
  const url = id && id !== '' ? `/owners/${id}` : '/owners';
  try {
    dispatch(requestSaveOwner());
    let response;
    if (id) {
      response = await put(session, url, owner);
    } else {
      response = await post(session, url, owner);
    }
    dispatch(receiveSaveOwner(response));
    return dispatch(loadOwners());
  } catch (err) {
    dispatch(receiveSaveOwnerError(err));
  }
};

const initialState = {
  loading: false,
  loaded: false,
  saveSuccess: false,
  saveError: false,
  saveErrorMessage: null,
  saveInProgress: false,
  error: false,
  tenants: null,
  owners: null,
  vendors: null,
  properties: null,
  managerProfile: null,
  meta: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_TENANTS': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_TENANTS_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_TENANTS': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        tenants: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_SAVE_TENANT': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_TENANT': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_TENANT_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: true,
        saveErrorMessage: serverError
      });
    }
    case 'REQUEST_MANAGER_PROFILE': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_MANAGER_PROFILE_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_MANAGER_PROFILE': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        managerProfile: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_SAVE_MANAGER_PROFILE': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_MANAGER_PROFILE': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_MANAGER_PROFILE_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;

      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: true,
        saveErrorMessage: serverError
      });
    }
    case 'REQUEST_OWNERS': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_OWNERS_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_OWNERS': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        owners: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_SAVE_OWNER': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_OWNER': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_OWNER_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: true,
        saveErrorMessage: serverError
      });
    }
    case 'REQUEST_VENDORS': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_VENDORS_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_VENDORS': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        vendors: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_PROPERTIES': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_PROPERTIES_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_PROPERTIES': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        properties: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_SAVE_PROPERTY': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_PROPERTY': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_PROPERTY_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: true,
        saveErrorMessage: serverError
      });
    }
    case 'REQUEST_SAVE_PROPERTIES': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_PROPERTIES': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_PROPERTIES_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;
      return Object.assign({}, state, {
        saveInProgress: false,
        saveError: true,
        saveSuccess: false,
        saveErrorMessage: serverError
      });
    }
    default:
      return state;
  }
};
