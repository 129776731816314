import * as API from '../utils/api';

const { get } = API;

// Action creators
const requestAvatar = () => ({
  type: 'REQUEST_AVATAR'
});

const receiveAvatar = (userId, payload) => ({
  type: 'RECEIVE_AVATAR',
  payload: payload,
  meta: {
    userId
  }
});

const receiveAvatarError = err => ({
  type: 'RECEIVE_AVATAR_ERROR',
  error: true,
  payload: err
});

export const loadAvatar = user_id => async (dispatch, getState) => {
  const path = `/users/${user_id}`;
  const session = getState().user.session;

  dispatch(requestAvatar());
  try {
    const response = await get(session, path);
    const data = await response.json();
    const uri = data.users.length ? data.users[0].picture_url : null;
    return dispatch(receiveAvatar(user_id, uri));
  } catch (err) {
    dispatch(receiveAvatarError(err));
  }
};

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  uris: [],
  message: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_AVATAR': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_AVATAR': {
      return Object.assign({}, state, {
        loading: false,
        loaded: true,
        error: false,
        uris: action.payload
          ? state.uris.concat({ [action.meta.userId]: action.payload })
          : state.uris
      });
    }
    case 'RECEIVE_AVATAR_ERROR': {
      return Object.assign({}, state, {
        loading: false,
        error: true,
        message: action.payload.message
      });
    }
    default:
      return state;
  }
};
