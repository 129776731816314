import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Common.css';
import MendHeader from './MendHeader';
import VendorList from './VendorList';

class Vendors extends Component {
  render() {
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content-with-table">
          <div className="title">Vendors</div>
          <VendorList />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ user }) => ({ user }),
  {}
)(Vendors);
