import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { withRouter } from 'react-router';

import Login from './Login';
import Home from './Home';
import Register from './Register';
import TenRegister from './TenRegister';
import ManagerProfile from './ManagerProfile';
import ProfileUpdateConfirmation from './ProfileUpdateConfirmation';
import RegisterConfirmation from './RegisterConfirmation';
import Owners from './Owners';
import OwnerProfile from './OwnerProfile';
import AddEditOwner from './AddEditOwner';
import Tenants from './Tenants';
import AddEditTenant from './AddEditTenant';
import TenantProfile from './TenantProfile';
import Properties from './Properties';
import AddEditProperty from './AddEditProperty';
import Vendors from './Vendors';
import VendorProfile from './VendorProfile';
import MendFooter from './MendFooter';
import Terms from './Terms';
import Privacy from './Privacy';

import { isUsernameValid } from '../utils/misc';

import './Common.css';

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      // XXX
      // check the token is valid, somewhere
      user.session ? (
        <Component {...props} user={user} />
      ) : (
        <Redirect to={{ pathname: '/login', props }} />
      )
    }
  />
);

class App extends Component {
  state = {
    session: this.props.user.session
  };

  componentDidMount() {
    document.title = 'The Mend - On-Demand Rental Apartment Maintenance';
  }

  componentWillReceiveProps(props) {
    const session = props.user.session;
    const oldSession = this.state.session;
    this.setState({ session });
    if (session && !oldSession) {
      this.props.history.replace('/');
    } else if (!session && oldSession) {
      this.props.history.replace('/login');
    }
  }

  register(params) {
    const username = isUsernameValid(params.username) ? params.username : '';
    if (params.role === 'tenant' || params.role === 'owner') {
      // tenant and owner have to be invited
      return (
        <TenRegister
          role={params.role}
          username={username}
          history={this.props.history}
        />
      );
    } else {
      // manager and vendor are allowed to register on their own
      return <Register history={this.props.history} />;
    }
  }

  render() {
    const params = queryString.parse(this.props.location.search);

    return (
      <div>
        <div className="app">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/register"
              render={() => this.register(params)}
            />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/"
              component={Home}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/manager_profile"
              component={ManagerProfile}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/owner_profile"
              component={OwnerProfile}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/tenant_profile"
              component={TenantProfile}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/vendor_profile"
              component={VendorProfile}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/profile_update_confirmation"
              component={ProfileUpdateConfirmation}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/register_confirmation"
              component={RegisterConfirmation}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/properties/new"
              component={AddEditProperty}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/properties/:id"
              component={AddEditProperty}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/properties"
              component={Properties}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/owners/new"
              component={AddEditOwner}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/owners/:ownerId"
              component={AddEditOwner}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/owners"
              component={Owners}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/tenants/new"
              component={AddEditTenant}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/tenants/:id"
              component={AddEditTenant}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/tenants"
              component={Tenants}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/tenants/:tenantId"
              component={TenantProfile}
            />
            <PrivateRoute
              user={this.props.user}
              exact
              path="/vendors"
              component={Vendors}
            />
            <PrivateRoute
              session={this.props.user.session}
              exact
              path="/vendors/:vendorId"
              component={VendorProfile}
            />
          </Switch>
        </div>
        <MendFooter history={this.props.history} />
      </div>
    );
  }
}

export default withRouter(connect(({ user }) => ({ user }))(App));
