import React, { Component } from 'react';

import { FormErrors } from './FormErrors';
import MendHeader from './MendHeader';
import FormField from './FormField';

import { post } from '../utils/api';
import { isUsernameValid } from '../utils/misc';

import './Common.css';
import './Register.css';

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.state = {
      step: props.role === 'tenant' || props.role === 'owner' ? 1 : 0,
      username: props.username ? props.username.toLowerCase() : '',
      isOwner: false,
      password: '',
      passwordCopy: '',
      formErrors: {
        username: '',
        password: '',
        passwordCopy: '',
        role: '',
        company: '',
        category: ''
      },
      usernameValid: isUsernameValid(props.username),
      passwordValid: false,
      roleValid: false,
      passwordCopyValid: false,
      role: props.role || '',
      company: '',
      category: 'Carpenter',
      companyValid: false,
      form0Valid: false,
      form1Valid: false,
      form2Valid: false
    };
  }

  handleChange(event) {
    if (this.state.step === 0) {
      const target = event.target;
      this.setState({ role: target['value'] }, () => {
        this.validateFieldAndForm('role', target['value']);
      });
    } else {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({ [name]: value }, () => {
        this.validateFieldAndForm(name, value);
      });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    if (this.state.step === 0) {
      this.setState({ step: 1 });
    } else if (this.state.step === 1) {
      this.setState({ step: 2 });
    } else {
      const username = this.state.username.toLowerCase(),
        password = this.state.password,
        company = this.state.company,
        role = this.state.role,
        category = this.state.category.toLowerCase();
      const url = '/user/register';
      const body = {
        company,
        username,
        password,
        role,
        category
      };
      post(null, url, body)
        .then(res => {
          this.props.history.push('/register_confirmation');
        })
        .catch(err => {
          console.log(JSON.stringify(err));
        });
    }
  }

  handleBackButtonClick(e) {
    this.setState({ step: this.state.step === 0 ? 0 : this.state.step - 1 });
  }

  validateFieldAndForm(fieldName, value) {
    const validFields = this.validateField(fieldName, value);
    this.setState(validFields, this.validateForm);
  }

  validateField(fieldName, value) {
    const vf = {
      formErrors: this.state.formErrors,
      usernameValid: this.state.usernameValid,
      passwordValid: this.state.passwordValid,
      passwordCopyValid: this.state.passwordCopyValid
    };

    switch (fieldName) {
      case 'username':
        vf.usernameValid = isUsernameValid(value);
        vf.formErrors.username = vf.usernameValid ? '' : 'Username is invalid';
        break;
      case 'password':
        vf.passwordValid = value.length >= 6;
        vf.formErrors.password = vf.passwordValid
          ? ''
          : 'Password is too short';
        break;
      case 'passwordCopy':
        vf.passwordCopyValid = this.state.passwordCopy === this.state.password;
        vf.formErrors.passwordCopy = vf.passwordCopyValid
          ? ''
          : 'Password copy does not match';
        break;
      case 'role':
        vf.roleValid = this.state.role !== '';
        vf.formErrors.role = vf.roleValid ? '' : 'Please select a role';
        break;
      case 'company':
        vf.companyValid = value.match(/^([\w\d]+)$/i);
        vf.formErrors.company = vf.companyValid
          ? ''
          : 'Company name must begin with a letter or a digit';
        break;
      default:
        break;
    }
    return vf;
  }

  validateForm() {
    this.setState({
      form0Valid: this.state.role === 'manager' || this.state.role === 'vendor',
      form1Valid: this.state.usernameValid,
      form2Valid: this.state.passwordValid && this.state.passwordCopyValid
    });
  }

  errorClass(error) {
    return error.length === 0 ? '' : '-has-error';
  }

  renderStep0() {
    return (
      <form onSubmit={this.handleFormSubmit}>
        <FormField
          label="I manage properties"
          name="role"
          value="manager"
          type="radio"
          checked={this.state.role === 'manager'}
          onChange={this.handleChange}
        />
        <FormField
          label="I service properties"
          name="role"
          value="vendor"
          type="radio"
          checked={this.state.role === 'vendor'}
          onChange={this.handleChange}
        />
        <button
          className="form-submit"
          type="submit"
          disabled={!this.state.form0Valid}
        >
          Next
        </button>
      </form>
    );
  }

  renderStep1() {
    return (
      <form onSubmit={this.handleFormSubmit}>
        <FormField
          label="Username"
          placeholder="E-mail or phone"
          name="username"
          type="text"
          formErrors={this.state.formErrors.username}
          value={this.state.username}
          onChange={this.handleChange}
        />
        <FormField
          label="Your company"
          placeholder="Company name"
          name="company"
          formErrors={this.state.formErrors.company}
          value={this.state.company}
          onChange={this.handleChange}
        />
        {this.state.role === 'manager' ? (
          <div className="field-register-form">
            <label>
              <input
                className="form-checkbox"
                name="isOwner"
                type="checkbox"
                checked={this.state.isOwner}
                onChange={this.handleChange}
              />
              Are you managing your own properties?
            </label>
          </div>
        ) : (
          <div className="field-register-form">
            <label htmlFor="category">Category</label>
            <select
              name="category"
              className={`form-item${this.errorClass(
                this.state.formErrors.category
              )}`}
              value={this.state.category}
              onChange={this.handleChange.bind(this)}
            >
              {[
                { name: 'Carpenter', value: 'carpentry' },
                { name: 'Electrician', value: 'electrical' },
                { name: 'Plumber', value: 'plumbing' },
                { name: 'Other', value: 'other' }
              ].map(item => (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
            <div className="myrectangle" />
          </div>
        )}
        <button
          className="form-submit"
          type="submit"
          disabled={!this.state.form1Valid}
        >
          Next
        </button>
      </form>
    );
  }

  renderStep2() {
    return (
      <form onSubmit={this.handleFormSubmit}>
        <FormField
          label="Password"
          placeholder="Password..."
          name="password"
          type="password"
          formErrors={this.state.formErrors.password}
          value={this.state.password}
          onChange={this.handleChange}
          autoComplete="new-password"
        />
        <FormField
          label="Confirm password"
          placeholder="Re-enter password"
          name="passwordCopy"
          type="password"
          formErrors={this.state.formErrors.passwordCopy}
          value={this.state.passwordCopy}
          onChange={this.handleChange}
          autoComplete="new-password"
        />
        <div className="row">
          <button
            className="form-submit"
            type="button"
            onClick={this.handleBackButtonClick}
          >
            Back
          </button>
          <button
            className="form-submit"
            type="submit"
            disabled={!this.state.form2Valid}
          >
            Sign up
          </button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="bG">
        <MendHeader history={this.props.history} register={true} />
        <div className="content">
          <div className="registration">Registration</div>
          <div className="error">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          {this.state.step === 1
            ? this.renderStep1()
            : this.state.step === 2
            ? this.renderStep2()
            : this.renderStep0()}
        </div>
      </div>
    );
  }
}

export default Register;
