import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadOwners, loadProperties, saveProperty } from '../modules/manager';

import MendHeader from './MendHeader';
import { FormErrors } from './FormErrors';

import states from './states.json';

const qs = require('query-string');

class AddEditProperty extends Component {
  state = {
    house: '',
    unit: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    ownerId: 0,
    formErrors: {
      zipcode: '',
      state: '',
      city: '',
      street: '',
      house: '',
      unit: '',
      ownerId: ''
    },
    formValid: false,
    zipcodeValid: false,
    cityValid: false,
    houseValid: false
  };

  componentWillMount() {
    if (!this.props.owners) {
      this.props.loadOwners();
    }
    if (!this.props.properties) {
      this.props.loadProperties();
    } else {
      const id = this.props.match.params.id || '';
      const parsedId = parseInt(id.substring(0, id.indexOf('&')));
      const unit = qs.parse(this.props.match.params.id)['unit'];
      const property = Object.values(this.props.properties).filter(
        entry => entry.id === parsedId && (!unit || entry.unit === unit)
      )[0];
      property &&
        this.setState({
          zipcode: property['zipcode'],
          state: property['state'],
          city: property['city'],
          street: property['street'],
          house: property['house'],
          ownerId: property['owner_id'],
          unit,
          zipcodeValid: true,
          streetValid: true,
          cityValid: true,
          houseValid: true,
          formValid: true
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    const id = this.props.match.params.id || '';
    const parsedId = parseInt(id.substring(0, id.indexOf('&')));
    const unit = qs.parse(this.props.match.params.id)['unit'];
    if (id !== '' && !this.props.properties && nextProps.properties) {
      const property = Object.values(nextProps.properties).filter(
        entry => entry.id === parsedId && (!unit || entry.unit === unit)
      )[0];
      this.setState({
        zipcode: property['zipcode'],
        state: property['state'],
        city: property['city'],
        street: property['street'],
        house: property['house'],
        ownerId: property['owner_id'],
        unit,
        zipcodeValid: true,
        cityValid: true,
        houseValid: true,
        streetValid: true,
        formValid: true
      });
    }
    if (nextProps.saveSuccess) {
      // saved a new property
      this.props.history.push('/properties');
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value }, () => {
      this.validateFieldAndForm(name, value);
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    let property = {
      house: this.state.house,
      unit: this.state.unit,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      ownerId: this.state.ownerId
    };
    const id = this.props.match.params.id || '';
    const parsedId = id.substring(0, id.indexOf('&'));
    if (parsedId !== '') {
      property['id'] = parsedId;
    }
    this.props.saveProperty(property);
  }

  errorClass(error) {
    return !error.length ? '' : '-has-error';
  }

  validateFieldAndForm(fieldName, value) {
    const validFields = this.validateField(fieldName, value);
    this.setState(validFields, this.validateForm);
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let zipcodeValid = this.state.zipcodeValid;
    let cityValid = this.state.cityValid;
    let streetValid = this.state.streetValid;
    let houseValid = this.state.houseValid;

    switch (fieldName) {
      case 'zipcode':
        zipcodeValid = /^\d{5}([\s-]\d{4})?$/.test(value);
        fieldValidationErrors.zipcode = zipcodeValid ? '' : 'Bad ZIP code';
        break;
      case 'city':
        cityValid = value.match(/^([\w].+)$/i);
        fieldValidationErrors.city = cityValid
          ? ''
          : 'City must begin with a letter';
        break;
      case 'street':
        streetValid = /^([\w].+)$/.test(value);
        fieldValidationErrors.street = streetValid
          ? ''
          : 'Street must begin with a letter';
        break;
      case 'house':
        houseValid = /^([\d].*)$/i.test(value);
        fieldValidationErrors.house = houseValid
          ? ''
          : 'House must begin with a digit';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        zipcodeValid: zipcodeValid,
        cityValid: cityValid,
        streetValid: streetValid,
        houseValid: houseValid
      },
      () => {
        this.validateForm();
      }
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.zipcodeValid &&
        this.state.cityValid &&
        this.state.streetValid &&
        this.state.houseValid &&
        this.state.ownerId !== 0
    });
  }

  render() {
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content">
          <div className="profile">{}</div>
          <div className="error">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          <form>
            <div className="field-profile-form">
              <label htmlFor="state">Owner</label>
              <select
                name="ownerId"
                className={`form-item${this.errorClass(
                  this.state.formErrors.ownerId
                )}`}
                value={this.state.ownerId}
                onChange={this.handleChange.bind(this)}
              >
                {[{ id: 0, name: 'Owner' }]
                  .concat(this.props.owners || [])
                  .map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="house">House number</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.house
                )}`}
                placeholder="House number"
                name="house"
                type="text"
                value={this.state.house}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="house">Unit</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.unit
                )}`}
                placeholder="Unit"
                name="unit"
                type="text"
                value={this.state.unit}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="street">Street</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.street
                )}`}
                placeholder="Street"
                name="street"
                type="text"
                value={this.state.street}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="city">City</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.city
                )}`}
                placeholder="City"
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="state">State</label>
              <select
                name="state"
                className={`form-item${this.errorClass(
                  this.state.formErrors.state
                )}`}
                value={this.state.state}
                onChange={this.handleChange.bind(this)}
              >
                {states.map(item => (
                  <option key={item.abbreviation} value={item.abbreviation}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="zipcode">ZIP code</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.zipcode
                )}`}
                placeholder="ZIP code"
                name="zipcode"
                type="text"
                value={this.state.zipcode}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <button
              className="form-submit"
              type="submit"
              disabled={!this.state.formValid}
              onClick={e => this.handleFormSubmit(e)}
            >
              Save
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ manager }) => ({
    owners: manager['owners'],
    properties: manager['properties'],
    saveSuccess: manager['saveSuccess']
  }),
  {
    loadOwners,
    loadProperties,
    saveProperty
  }
)(AddEditProperty);
