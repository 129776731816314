import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as user } from './user';
import { reducer as avatar } from './avatar';
import { reducer as manager } from './manager';
import { reducer as tenant } from './tenant';
import { reducer as owner } from './owner';
import { reducer as vendor } from './vendor';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    avatar,
    manager,
    tenant,
    owner,
    vendor
    // rest of your reducers
  });
