import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Common.css';
import './MendHeader.css';
import logo from '../assets/mend-logo-medium.png';

class MendHeader extends Component {
  componentWillMount() {
    // document.title = 'The Mend';
  }

  handleLoginButtonClick(e) {
    if (this.props.register) {
      this.props.history.push('/login');
    } else if (this.props.session) {
      //this.props.auth.logout()
      this.props.history.push('/login');
    } else {
      this.props.history.push('/register');
    }
  }

  handleProfileButtonClick(e) {
    const roles = this.props.session.roles;
    if (roles.is_manager) {
      this.props.history.push('/manager_profile');
    } else if (roles.is_vendor) {
      this.props.history.push('/vendor_profile');
    } else if (roles.is_tenant) {
      this.props.history.push('/tenant_profile');
    } else if (roles.is_owner) {
      this.props.history.push('/owner_profile');
    } else {
      console.warn('not handled');
    }
  }

  getLoginButtonText() {
    return this.props.register
      ? 'Log In'
      : this.props.session
      ? 'Log out'
      : 'Register';
  }

  renderProfileButton() {
    if (this.props.session && !this.props.noProfile) {
      return (
        <div
          className="button-header-profile"
          onClick={this.handleProfileButtonClick.bind(this)}
        >
          Edit Profile
        </div>
      );
    }
  }

  render() {
    return (
      <div className="rectangle-header" style={{ width: '100%' }}>
        <Link to="/" className="logo">
          <img
            src={logo}
            alt="MEND"
            style={{ height: '100%' }}
            onClick={() => this.props.history.push('/')}
          />
        </Link>
        <div className="right-part">
          {this.renderProfileButton()}
          <div
            className="button-header-login"
            onClick={this.handleLoginButtonClick.bind(this)}
          >
            {this.getLoginButtonText()}
          </div>
        </div>
      </div>
    );
  }
}

export default MendHeader;
