import decode from 'jwt-decode';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();
const backend = env.REACT_APP_BACKEND || 'http://localhost:5001';

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (err) {
    return false;
  }
}

export function isLoggedIn(session) {
  const token = session ? session.token : null;
  return !!token && !isTokenExpired(token);
}

function getHeaders(session) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  if (isLoggedIn(session)) {
    headers['Authorization'] = `Bearer ${session.token}`;
  }
  return headers;
}

async function execute(headers, url, method, body = undefined) {
  const response = await fetch(url, { headers, method, body });
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    if (response.status === 401) {
      console.log('we need to do redirect saga or here we should dispatch:', {
        type: 'LOGOUT_USER'
      });
    }
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export async function post(session, path, body) {
  if (typeof body === 'object') {
    body = JSON.stringify(body);
  }
  return execute(getHeaders(session), backend + path, 'POST', body);
}

export async function put(session, path, data) {
  if (typeof data === 'object') {
    data = JSON.stringify(data);
  }
  return execute(getHeaders(session), backend + path, 'PUT', data);
}

export async function get(session, path) {
  return execute(getHeaders(session), backend + path, 'GET');
}

export async function del(session, path) {
  return execute(getHeaders(session), backend + path, 'DELETE');
}

export async function uploadFile(session, file) {
  const headers = {
    Accept: 'application/json'
  };
  if (isLoggedIn(session)) {
    headers['Authorization'] = `Bearer ${session.token}`;
  }
  const formData = new FormData();
  formData.append('file', file);
  return execute(headers, `${backend}/upload_data`, 'POST', formData);
}
