import React, { Component } from 'react';
import './Common.css';
import './MendFooter.css';

class MendFooter extends Component {
  render() {
    return (
      <div className="rectangle-footer">
        <div className="copyright-notice-1">
          Copyright &copy; All rights reserved.
        </div>
        <div className="footer-right-part">
          <div
            className="footer-link"
            style={{ marginLeft: 0 }}
            onClick={() => this.props.history.push('/privacy')}
          >
            Privacy
          </div>
          <div
            className="footer-link"
            onClick={() => this.props.history.push('/terms')}
          >
            Terms
          </div>
        </div>
        <div className="copyright-notice-2">
          Copyright &copy; All rights reserved.
        </div>
      </div>
    );
  }
}

export default MendFooter;
