import * as API from '../utils/api';

const { get, put } = API;

const requestOwnerProfile = id => ({
  type: 'REQUEST_OWNER_PROFILE',
  meta: id
});

const receiveOwnerProfile = (data, meta) => ({
  type: 'RECEIVE_OWNER_PROFILE',
  payload: data,
  meta: meta
});

const receiveOwnerProfileError = err => ({
  type: 'RECEIVE_OWNER_PROFILE_ERROR',
  error: true,
  payload: err
});

const requestSaveOwnerProfile = id => ({
  type: 'REQUEST_SAVE_OWNER_PROFILE',
  meta: id
});

const receiveSaveOwnerProfile = () => ({
  type: 'RECEIVE_SAVE_OWNER_PROFILE'
});

const receiveSaveOwnerProfileError = err => ({
  type: 'RECEIVE_SAVE_OWNER_PROFILE_ERROR',
  error: true,
  payload: err
});

export const loadProfile = id => (dispatch, getState) => {
  const url = `/owners/${id}`;
  const session = getState().user.session;
  dispatch(requestOwnerProfile(id));
  return get(session, url)
    .then(response =>
      response.json().then(data => {
        dispatch(receiveOwnerProfile(data));
      })
    )
    .catch(err => dispatch(receiveOwnerProfileError(err)));
};

export const saveProfile = (profile, id) => (dispatch, getState) => {
  const url = `/owners/${id}`;
  const session = getState().user.session;
  dispatch(requestSaveOwnerProfile(id));
  return put(session, url, profile)
    .then(response => {
      dispatch(receiveSaveOwnerProfile(response));
      return dispatch(loadProfile(id));
    })
    .catch(err => dispatch(receiveSaveOwnerProfileError(err)));
};

const initialState = {
  loading: false,
  loaded: false,
  saveSuccess: false,
  saveError: false,
  saveErrorMessage: null,
  saveInProgress: false,
  error: false,
  ownerProfile: null,
  meta: null
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REQUEST_OWNER_PROFILE': {
      return Object.assign({}, state, {
        loading: true,
        error: false
      });
    }
    case 'RECEIVE_OWNER_PROFILE_ERROR': {
      return Object.assign({}, state, {
        error: true,
        loading: false,
        loaded: true
      });
    }
    case 'RECEIVE_OWNER_PROFILE': {
      return Object.assign({}, state, {
        error: false,
        loading: false,
        loaded: true,
        ownerProfile: action.payload,
        meta: action.meta
      });
    }
    case 'REQUEST_SAVE_OWNER_PROFILE': {
      return Object.assign({}, state, {
        saveInProgress: true,
        saveSuccess: false,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_OWNER_PROFILE': {
      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: false
      });
    }
    case 'RECEIVE_SAVE_OWNER_PROFILE_ERROR': {
      const { data } = action.payload ? action.payload.response : {};
      const serverError =
        data && data.message ? `SERVER ERROR: ${data.message}` : null;

      return Object.assign({}, state, {
        saveInProgress: false,
        saveSuccess: true,
        saveError: true,
        saveErrorMessage: serverError
      });
    }
    default:
      return state;
  }
};
