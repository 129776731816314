import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormErrors } from './FormErrors';
import MendHeader from './MendHeader';

import { loadProfile, saveProfile } from '../modules/tenant';

import states from './states.json';

import './Common.css';
import './TenantProfile.css';

/*
CREATE TABLE IF NOT EXISTS users (
  id SERIAL PRIMARY KEY,
  username VARCHAR(254) UNIQUE NOT NULL,
  name VARCHAR(100) DEFAULT NULL,
  password VARCHAR(60) NOT NULL
  company VARCHAR(100) DEFAULT NULL,
  profile_picture VARCHAR(254) DEFAULT NULL,
  email VARCHAR(254) DEFAULT NULL,
  reg_stage INTEGER NOT NULL DEFAULT 0);

CREATE TABLE IF NOT EXISTS managers (
  id SERIAL PRIMARY KEY,
  user_id INTEGER NOT NULL REFERENCES users (id)
) INHERITS(addresses);

CREATE TABLE IF NOT EXISTS addresses (
  zipcode VARCHAR(9),
  state CHAR(2),
  city VARCHAR(60),
  street VARCHAR(60),
  house VARCHAR(30)
);
*/

class TenantProfile extends Component {
  state = {
    id: 0,
    userId: 0,
    username: '',
    company: '',
    name: '',
    profilePicture: '',
    email: '',
    zipcode: '',
    state: '',
    city: '',
    street: '',
    house: '',
    phoneType: '',
    phone: '',
    formErrors: {
      name: '',
      email: '',
      zipcode: '',
      state: '',
      city: '',
      street: '',
      house: '',
      phoneType: '',
      phone: ''
    },
    nameValid: false,
    emailValid: false,
    zipcodeValid: false,
    stateValid: false,
    cityValid: false,
    streetValid: false,
    houseValid: false,
    phoneTypeValid: false,
    phoneValid: false,
    formValid: false
  };

  componentWillMount() {
    if (!this.props.profile) {
      this.props.loadProfile(0);
    } else {
      this.setState({
        id: this.props.profile.id,
        userId: this.props.profile.user_id,
        username: this.props.profile.username || '',
        company: this.props.profile.company || '',
        profilePicture: this.props.profile.profile_picture || '',
        nameValid: true,
        emailValid: true,
        zipcodeValid: true,
        stateValid: true,
        cityValid: true,
        streetValid: true,
        houseValid: true,
        phoneTypeValid: true,
        phoneValid: true,
        formValid: true
      });
      this.setValidState('name', this.props.profile.name);
      this.setValidState(
        'email',
        this.props.profile.email
          ? this.props.profile.email
          : this.props.profile.username
      );
      this.setValidState('zipcode', this.props.profile.zipcode);
      this.setValidState('state', this.props.profile.state);
      this.setValidState('city', this.props.profile.city);
      this.setValidState('street', this.props.profile.street);
      this.setValidState('house', this.props.profile.house);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile && nextProps.profile.id) {
      this.setState({
        id: nextProps.profile.id,
        userId: nextProps.profile.user_id,
        username: nextProps.profile.username || '',
        company: nextProps.profile.company || '',
        profilePicture: nextProps.profile.profile_picture || '',
        nameValid: true,
        emailValid: true,
        zipcodeValid: true,
        stateValid: true,
        cityValid: true,
        streetValid: true,
        houseValid: true,
        phoneTypeValid: true,
        phoneValid: true,
        formValid: true
      });
      this.setValidState('name', nextProps.profile.name);
      this.setValidState(
        'email',
        nextProps.profile.email
          ? nextProps.profile.email
          : nextProps.profile.username
      );
      this.setValidState('zipcode', nextProps.profile.zipcode);
      this.setValidState('state', nextProps.profile.state);
      this.setValidState('city', nextProps.profile.city);
      this.setValidState('street', nextProps.profile.street);
      this.setValidState('house', nextProps.profile.house);
    }
  }

  setValidState(name, value) {
    if (value == null) value = '';
    this.setState({ [name]: value }, () => {
      this.validateField(name, value);
    });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setValidState(name, value);
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let id = this.state.id;
    let user_id = this.state.userId;
    let username = this.state.username;
    let company = this.state.company;
    let name = this.state.name;
    let profile_picture = this.state.profilePicture;
    let email = this.state.email;
    let zipcode = this.state.zipcode;
    let state = this.state.state;
    let city = this.state.city;
    let street = this.state.street;
    let house = this.state.house;

    this.props
      .saveProfile(
        {
          id,
          user_id,
          username,
          company,
          name,
          profile_picture,
          email,
          zipcode,
          state,
          city,
          street,
          house
        },
        0
      )
      .then(() => {
        this.props.history.push('/profile_update_confirmation');
      })
      .catch(err => console.log(err));
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let nameValid = this.state.nameValid;
    let emailValid = this.state.emailValid;
    let zipcodeValid = this.state.zipcodeValid;
    let stateValid = this.state.stateValid;
    let cityValid = this.state.cityValid;
    let streetValid = this.state.streetValid;
    let houseValid = this.state.houseValid;

    switch (fieldName) {
      case 'name':
        nameValid = value.match(/^([\w].+)$/i);
        fieldValidationErrors.name = nameValid
          ? ''
          : 'Personal name must begin with a letter';
        break;
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'E-mail is invalid';
        break;
      case 'zipcode':
        zipcodeValid = value.match(/^\d{5}([\s-]\d{4})?$/);
        fieldValidationErrors.zipcode = zipcodeValid ? '' : 'Bad ZIP code';
        break;
      case 'state':
        stateValid = value.match(/^[A-Z]{2}$/);
        fieldValidationErrors.state = stateValid ? '' : 'Bad state code';
        break;
      case 'city':
        cityValid = value.match(/^([\w].+)$/i);
        fieldValidationErrors.city = cityValid
          ? ''
          : 'City must begin with a letter';
        break;
      case 'street':
        streetValid = value.match(/^([\w].+)$/i);
        fieldValidationErrors.street = streetValid
          ? ''
          : 'Street must begin with a letter';
        break;
      case 'house':
        houseValid = value.match(/^([\d].*)$/i);
        fieldValidationErrors.house = houseValid
          ? ''
          : 'House must begin with a digit';
        break;
      default:
        break;
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        emailValid: emailValid,
        zipcodeValid: zipcodeValid,
        stateValid: stateValid,
        cityValid: cityValid,
        streetValid: streetValid,
        houseValid: houseValid
      },
      this.validateForm
    );
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.nameValid &&
        this.state.emailValid &&
        this.state.zipcodeValid &&
        this.state.stateValid &&
        this.state.cityValid &&
        this.state.streetValid &&
        this.state.houseValid
    });
  }

  errorClass(error) {
    return error.length === 0 ? '' : '-has-error';
  }

  render() {
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content">
          <div className="profile">{this.state.name}</div>
          <div className="error">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          <form onSubmit={this.handleFormSubmit.bind(this)}>
            <div className="field-profile-form">
              <label htmlFor="name">Your name</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.name
                )}`}
                placeholder="Your name"
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="email">Your e-mail</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.email
                )}`}
                placeholder="E-mail"
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="zipcode">ZIP code</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.zipcode
                )}`}
                placeholder="ZIP code"
                name="zipcode"
                type="text"
                value={this.state.zipcode}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="state">Your state</label>
              <select
                name="state"
                className={`form-item${this.errorClass(
                  this.state.formErrors.state
                )}`}
                value={this.state.state}
                onChange={this.handleChange.bind(this)}
              >
                {states.map(item => (
                  <option key={item.abbreviation} value={item.abbreviation}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="city">Your city</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.city
                )}`}
                placeholder="City"
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="street">Your street</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.street
                )}`}
                placeholder="Street"
                name="street"
                type="text"
                value={this.state.street}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <div className="field-profile-form">
              <label htmlFor="house">House number</label>
              <input
                className={`form-item${this.errorClass(
                  this.state.formErrors.house
                )}`}
                placeholder="House number"
                name="house"
                type="text"
                value={this.state.house}
                onChange={this.handleChange.bind(this)}
              />
              <div className="rectangle" />
            </div>
            <button
              className="form-submit"
              type="submit"
              disabled={!this.state.formValid}
            >
              Update
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ tenant }, ownProps) => ({
    profile: tenant['tenantProfile']
  }),
  {
    loadProfile,
    saveProfile
  }
)(TenantProfile);
