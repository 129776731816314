import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import MendAvatar from './MendAvatar';
import { loadTenants } from '../modules/manager';
import { toTitleCase } from '../utils/fomatter';

import './Common.css';
import './TenantProfile.css';
import './TenantList.css';

const tenantFormatter = (cell, row, formatExtraData) => {
  const address = `${row.house} ${toTitleCase(row.street)}`;
  return (
    <Link
      to={`/tenants/${row.id}`}
      style={{ textDecoration: 'none', color: '#3b4e81' }}
    >
      <div className="cell">
        <div className="cell-v-ava">
          <MendAvatar user_id={row.user_id} size={36} />
        </div>
        <div className="cell-v-extra">
          <div className="cell-data">{row.name || '-'}</div>
          <div className="cell-data">{`${address} # ${row.unit}`}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Name</div>
          <div className="cell-data">{row.name || '-'}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Address</div>
          <div className="cell-data">{address}</div>
        </div>
        <div className="cell-v-narrow">
          <div className="cell-header">Unit</div>
          <div className="cell-data">{row.unit || '-'}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Email</div>
          <div className="cell-data">{row.email || '-'}</div>
        </div>
      </div>
    </Link>
  );
};

/**
 * Construct a table row of the following available fields:
 * id, user_id, username, name, profile_picture, email, reg_stage, unit,
 * moved_in, property_id, zipcode, state,  city, street, house,
 * phone_type, phone_number, owner_id, owner_username, owner_name.
 */
class TenantList extends Component {
  componentWillMount() {
    if (!this.props.tenants) {
      this.props.loadTenants();
    }
  }

  render() {
    const options = {
      noDataText: 'No data',
      // Pager options
      hideSizePerPage: true,
      pageStartIndex: 1,
      paginationSize: 0,
      sizePerPage: 6,
      prePage: '<',
      nextPage: '>'
    };

    if (!this.props.tenants || !this.props.tenants.length) {
      return <div>No data</div>;
    } else {
      return (
        <div className="with-overflow">
          <BootstrapTable
            data={this.props.tenants}
            pagination
            options={options}
            bordered={false}
            trClassName="tr-class"
            className="table-class"
            tableHeaderClass="tr-class"
            tableBodyClass="tr-class"
          >
            <TableHeaderColumn
              className="col-hidden"
              columnClassName="td-class"
              hidden={true}
              dataField="user_id"
              isKey
            >
              User ID
            </TableHeaderColumn>
            <TableHeaderColumn
              className="col-hidden"
              columnClassName="td-class"
              dataFormat={tenantFormatter}
              formatExtraData={'Name'}
              dataField="name"
            >
              Name
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    }
  }
}

export default connect(
  ({ manager }) => ({
    tenants: manager['tenants']
  }),
  {
    loadTenants
  }
)(TenantList);
