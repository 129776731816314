import React from 'react';

export const FormErrors = ({ formErrors }) => {
  let formError = '';
  for (let fieldName of Object.keys(formErrors)) {
    if (formErrors[fieldName].length > 0) {
      formError = formErrors[fieldName];
      break;
    }
  }
  return <div className="formErrors">{formError}</div>;
};
