function titleCase(str) {
  if (!str) return '';

  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

function phoneNumberFormat(num) {
  let str = num.toString();
  const rgx1 = /^(\d+)(\d{3})(\d{3})(\d{4})$/;
  const rgx2 = /^(\d+)(\d{3})(\d{4})$/;
  const rgx3 = /^(\d+)(\d{4})$/;
  if (rgx1.test(str)) {
    return str.replace(rgx1, '+$1 ($2) $3-$4');
  }
  if (rgx2.test(str)) {
    return str.replace(rgx2, '($1) $2-$3');
  }
  if (rgx3.test(str)) {
    return str.replace(rgx3, '$1-$2');
  }
  return str;
}

function formatAddress(row) {
  const arr = [];
  if (!isEmptyOrSpaces(row.house)) arr.push(row.house);
  if (!isEmptyOrSpaces(row.street)) arr.push(titleCase(row.street));
  const streetAddr = arr.join(' ');
  const arr2 = [];
  if (!isEmptyOrSpaces(streetAddr)) arr2.push(streetAddr);
  if (!isEmptyOrSpaces(row.unit)) arr2.push('unit ' + row.unit);
  if (!isEmptyOrSpaces(row.city)) arr2.push(titleCase(row.city));
  return arr2.join(', ') || '';
}

function formatAddressHSC(row) {
  const arr = [];
  if (!isEmptyOrSpaces(row.house)) arr.push(row.house);
  if (!isEmptyOrSpaces(row.street)) arr.push(titleCase(row.street));
  const streetAddr = arr.join(' ');
  const arr2 = [];
  if (!isEmptyOrSpaces(streetAddr)) arr2.push(streetAddr);
  if (!isEmptyOrSpaces(row.city)) arr2.push(titleCase(row.city));
  return arr2.join(', ') || '';
}

function formatPhone(row) {
  let { phone_number, phone_type } = row;
  if (!phone_number) return '';

  return (
    (phone_type && phone_type.length > 0 ? phone_type + ': ' : '') +
    phoneNumberFormat(phone_number)
  );
}

function formatPhones(row) {
  let res = '';
  if (!row.numbers) return '';

  let phone_numbers = Array.isArray(row.numbers) ? row.numbers : [row.numbers];
  let phone_types = row.types
    ? Array.isArray(row.types)
      ? row.types
      : [row.types]
    : [];

  for (let i = 0; i < phone_numbers.length; i++) {
    if (i > 0) res += '; ';
    if (i < phone_types.length && phone_types[i].length > 0)
      res += phone_types[i] + ': ';
    res += phoneNumberFormat(phone_numbers[i]);
  }

  return res;
}

function formatDateMMDDYYYY(d) {
  return (
    (d.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    d
      .getDate()
      .toString()
      .padStart(2, '0') +
    '.' +
    d.getFullYear()
  );
}

function formatDateMMDDYY(d) {
  return (
    (d.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    d
      .getDate()
      .toString()
      .padStart(2, '0') +
    '.' +
    (d.getFullYear() % 100).toString().padStart(2, '0')
  );
}

function formatDateMMDD(d) {
  return (
    (d.getMonth() + 1).toString().padStart(2, '0') +
    '.' +
    d
      .getDate()
      .toString()
      .padStart(2, '0')
  );
}

function formatDateMMDD2(d) {
  return (
    (d.getMonth() + 1).toString().padStart(2, '0') +
    '/' +
    d
      .getDate()
      .toString()
      .padStart(2, '0')
  );
}

function formatTimeHHMM_AM(d) {
  const h24 = d.getHours();
  const pm = h24 > 11 ? ' PM' : ' AM';
  const h = h24 % 12 === 0 ? 12 : h24 % 12;
  return (
    h.toString().padStart(2, '0') +
    ':' +
    d
      .getMinutes()
      .toString()
      .padStart(2, '0') +
    pm
  );
}

function formatTimeHHMM(d) {
  return (
    d
      .getHours()
      .toString()
      .padStart(2, '0') +
    ' : ' +
    d
      .getMinutes()
      .toString()
      .padStart(2, '0')
  );
}

function isEmptyOrSpaces(str) {
  return str === undefined || str === null || str.match(/^ *$/) !== null;
}

function getProfession(category) {
  switch (category) {
    case 'carpentry':
      return 'Carpenter';
    case 'plumbing':
      return 'Plumber';
    case 'electrical':
      return 'Electrician';
    default:
      return 'Other';
  }
}

module.exports = {
  titleCase,
  formatPhone,
  formatPhones,
  phoneNumberFormat,
  formatAddress,
  formatAddressHSC,
  isEmptyOrSpaces,
  formatDateMMDDYYYY,
  formatDateMMDDYY,
  formatDateMMDD,
  formatDateMMDD2,
  formatTimeHHMM_AM,
  formatTimeHHMM,
  getProfession
};
