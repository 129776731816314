import React from 'react';

import Register from './Register';
import FormField from './FormField';

import * as API from '../utils/api';

import './Common.css';
import './Register.css';

const { post } = API;

class TenRegister extends Register {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      username: props.username,
      firstName: '',
      lastName: '',
      phone: '',
      formErrors: {
        ...this.state.formErrors,
        firstName: '',
        lastName: '',
        phone: ''
      },
      firstNameValid: false,
      lastNameValid: false,
      phoneValid: true,
      emailValid: true
    };
  }

  handleChange(event) {
    if (this.state.step === 0) {
      const target = event.target;
      this.setState({ role: target['value'] }, () => {
        this.validateFieldAndForm('role', target['value']);
      });
    } else {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({ [name]: value }, () => {
        this.validateFieldAndForm(name, value);
      });
    }
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    if (this.state.step === 1) {
      this.setState({ step: 2 });
      this.forceUpdate();
    } else {
      const username = this.state.username,
        email = this.state.email,
        password = this.state.password,
        name = `${this.state.firstName} ${this.state.lastName}`,
        role = this.props.role,
        phone = this.state.phone,
        url = '/user/register';
      const tenant = {
        username,
        email,
        password,
        name,
        role,
        phone
      };
      post(null, url, tenant)
        .then(res => {
          this.props.history.push('/register_confirmation');
        })
        .catch(err => {
          console.log(JSON.stringify(err));
        });
    }
  }

  validateField(fieldName, value) {
    const vf = super.validateField(fieldName, value);
    vf.firstNameValid = this.state.firstNameValid;
    vf.lastNameValid = this.state.lastNameValid;
    vf.phoneValid = this.state.phoneValid;

    switch (fieldName) {
      case 'firstName':
        vf.firstNameValid = /^([\w]+)$/.test(value);
        vf.formErrors.firstName = vf.firstNameValid
          ? ''
          : 'First name must begin with a letter';
        break;
      case 'lastName':
        vf.lastNameValid = /^([\w]+)$/.test(value);
        vf.formErrors.lastName = vf.lastNameValid
          ? ''
          : 'Last name must begin with a letter';
        break;
      case 'phone':
        vf.phoneValid = value.match(
          /^(\+?[0-9]+)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{2}[-. ]?[0-9]{2})$/
        );
        vf.formErrors.phone = vf.phoneValid
          ? ''
          : 'Incorrect phone number format';
        break;
      default:
        break;
    }

    return vf;
  }

  validateForm() {
    this.setState({
      form1Valid:
        this.state.firstNameValid &&
        this.state.lastNameValid &&
        this.state.emailValid &&
        this.state.phoneValid,
      form2Valid: this.state.passwordValid && this.state.passwordCopyValid
    });
  }

  renderStep1() {
    return (
      <form onSubmit={this.handleFormSubmit}>
        <div className="row">
          <div className="column">
            <FormField
              label="First name"
              placeholder="First name"
              name="firstName"
              formErrors={this.state.formErrors.firstName}
              value={this.state.firstName}
              onChange={this.handleChange.bind(this)}
            />
          </div>
          <div className="column">
            <FormField
              label="Last name"
              placeholder="Last name"
              name="lastName"
              formErrors={this.state.formErrors.lastName}
              value={this.state.lastName}
              onChange={this.handleChange.bind(this)}
            />
          </div>
        </div>
        <FormField
          label="E-mail address"
          placeholder="E-mail address"
          name="email"
          type="email"
          formErrors={this.state.formErrors.email}
          value={this.state.email}
          onChange={this.handleChange.bind(this)}
        />
        <FormField
          label="Telephone"
          placeholder="Telephone"
          name="phone"
          formErrors={this.state.formErrors.phone}
          value={this.state.phone}
          onChange={this.handleChange.bind(this)}
        />
        <button
          className="form-submit"
          type="submit"
          disabled={!this.state.form1Valid}
        >
          Next
        </button>
      </form>
    );
  }
}

export default TenRegister;
