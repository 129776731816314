import React, { Component } from 'react';
import { connect } from 'react-redux';

import MendHeader from './MendHeader';
import FileUpload from './FileUpload';
import TenantList from './TenantList';
import { savePropertiesInfo } from '../modules/manager';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import './Common.css';

library.add(faUser);

const AddTenant = ({ history }) => (
  <div
    style={{ marginLeft: 'auto', alignSelf: 'center', marginRight: '10px' }}
    onClick={() => history.push('/tenants/new')}
  >
    <div className="file-selector">
      <label htmlFor="file-selector" className="upload-label">
        <div className="upload-label-div">
          <div style={{ marginRight: '8px' }}>
            <FontAwesomeIcon icon="user" />
          </div>
          Add Tenant
        </div>
      </label>
    </div>
  </div>
);

class Tenants extends Component {
  state = {
    error: ''
  };

  async uploadHandler(file) {
    try {
      await this.props.savePropertiesInfo(file);
    } catch (err) {
      console.log(JSON.stringify(err));
      this.setState({ error: err });
    }
  }

  render() {
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content-with-table">
          <div className="row2">
            <div className="title">Tenants</div>
            <div>{this.state.error}</div>
            <AddTenant history={this.props.history} />
            <FileUpload handler={this.uploadHandler.bind(this)} />
          </div>
          <TenantList />
        </div>
      </div>
    );
  }
}
export default connect(
  ({ user, propertiesUpload }) => ({ user, propertiesUpload }),
  { savePropertiesInfo }
)(Tenants);
