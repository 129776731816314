import React, { Component } from 'react';
import { connect } from 'react-redux';

import profileIconStub from '../assets/profile_icon_stub.png';
import { loadAvatar } from '../modules/avatar';
import Avatar from 'react-avatar';

const findUrl = (immutable, id) => {
  const arr = immutable.filter(entry => {
    const k = parseInt(Object.keys(entry)[0]);
    return k === id;
  });
  return arr.length ? arr[0][`${id}`] : null;
};

class MendAvatar extends Component {
  componentWillMount() {
    if (
      !this.props.avatars ||
      !findUrl(this.props.avatars, this.props.user_id)
    ) {
      this.props.loadAvatar(this.props.user_id);
    }
  }

  render() {
    const src =
      findUrl(this.props.avatars, this.props.user_id) || profileIconStub;
    const { size } = this.props;
    return <Avatar size={size} src={src} round={true} />;
  }
}

export default connect(
  ({ avatar }, ownProps) => ({
    avatars: avatar['uris']
  }),
  {
    loadAvatar
  }
)(MendAvatar);
