import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './modules';
import { isTokenExpired } from './utils/api';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const configureStore = (initialState = {}, history) => {
  let composeEnhancers = compose;
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const enhancers = [];
  const middleware = [sagaMiddleware, thunk, routerMiddleware(history)];

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }

  const resetEnhancer = rootReducer => (state, action) => {
    if (action.type !== 'LOGOUT_USER') {
      return rootReducer(state, action);
    }
    const newState = rootReducer(undefined, action);
    newState.router = state.router;
    return newState;
  };

  const store = createStore(
    resetEnhancer(createRootReducer(history)),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  store.runSaga = sagaMiddleware.run;

  store.subscribe(() => {
    const state = store.getState();
    const { session } = state['user'];
    if (session) {
      const { token } = session;
      if (isTokenExpired(token)) {
        console.warn('token expired');
        store.dispatch({ type: 'LOGOUT_USER' });
      }
    }
  });

  return store;
};
export default configureStore({}, history);
