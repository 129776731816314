import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { loadOwners } from '../modules/manager';

import { isEmptyOrSpaces } from '../utils/MendFormat';
import { toTitleCase } from '../utils/fomatter';

import './Common.css';
import './TenantList.css';
/**
 * Construct a table row of the following available fields:
 * id, user_id, username, name, profile_picture, email, reg_stage, unit,
 * moved_in, property_id, zipcode, state,  city, street, house,
 * phone_type, phone_number, owner_id, owner_username, owner_name.
 */

function ownerFormatter(cell, row, formatExtraData) {
  const address = `${toTitleCase(
    `${row.house || ''} ${row.street || ''}, ${row.city || ''},`
  )} ${row.state || ''}`;
  return (
    <Link
      to={`/owners/${row.id}`}
      style={{ textDecoration: 'none', color: '#3b4e81' }}
    >
      <div className="cell">
        <div className="cell-v-extra" style={{ paddingLeft: 20 }}>
          {!isEmptyOrSpaces(row.company) ? (
            <div className="cell-data">{row.company}</div>
          ) : !isEmptyOrSpaces(row.name) ? (
            <div className="cell-data">{row.name}</div>
          ) : null}
          <div className="cell-data">{row.email || '-'}</div>
        </div>
        <div className="cell-v-wide" style={{ paddingLeft: 20 }}>
          <div className="cell-header">Company</div>
          <div className="cell-data">{row.company || '-'}</div>
        </div>
        <div className="cell-v-narrow">
          <div className="cell-header">Name</div>
          <div className="cell-data">{row.name || '-'}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Address</div>
          <div className="cell-data">{address}</div>
        </div>
        <div className="cell-v-wide">
          <div className="cell-header">Email</div>
          <div className="cell-data">{row.email || '-'}</div>
        </div>
      </div>
    </Link>
  );
}

class OwnerList extends Component {
  componentWillMount() {
    if (!this.props.owners) {
      this.props.loadOwners();
    }
  }

  render() {
    const options = {
      noDataText: 'No data',
      // Pager options
      hideSizePerPage: true,
      pageStartIndex: 1,
      paginationSize: 0,
      sizePerPage: 6,
      prePage: '<',
      nextPage: '>'
    };

    if (!this.props.owners || !this.props.owners.length) {
      return <div>No data</div>;
    } else {
      return (
        <div className="with-overflow">
          <BootstrapTable
            data={this.props.owners}
            pagination
            options={options}
            bordered={false}
            trClassName="tr-class"
            className="table-class"
            tableHeaderClass="tr-class"
            tableBodyClass="tr-class"
          >
            <TableHeaderColumn
              className="col-hidden"
              columnClassName="td-class"
              hidden={true}
              dataField="id"
              isKey
            >
              User ID
            </TableHeaderColumn>
            <TableHeaderColumn
              className="col-hidden"
              columnClassName="td-class"
              dataFormat={ownerFormatter}
              formatExtraData={'Name'}
              dataField="name"
            >
              Name
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    }
  }
}

export default connect(
  ({ manager }, ownProps) => ({
    owners: manager['owners']
  }),
  {
    loadOwners
  }
)(OwnerList);
