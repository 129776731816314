import React, { Component } from 'react';
import { connect } from 'react-redux';
import MendHeader from './MendHeader';

import './Common.css';

class Home extends Component {
  componentDidMount() {
    const { replace } = this.props.history;
    const { session } = this.props.user;
    const { roles } = session;

    if (!roles.is_manager) {
      if (roles.is_owner) {
        replace('/owner_profile');
      } else if (roles.is_vendor) {
        replace('/vendor_profile');
      } else if (roles.is_tenant) {
        replace('/tenant_profile');
      }
    }
  }

  render() {
    const { push } = this.props.history;
    return (
      <div className="bG">
        <MendHeader
          history={this.props.history}
          session={this.props.user.session}
        />
        <div className="content">
          <div>
            <button
              type="button"
              className="button-header-profile"
              onClick={() => push('/manager_profile')}
            >
              Edit Profile
            </button>
            <button
              type="button"
              className="button-wide"
              onClick={() => push('/properties')}
            >
              Properties
            </button>
            <span width="20">&nbsp;</span>
            <button
              type="button"
              className="button-wide"
              onClick={() => push('/tenants')}
            >
              Tenants
            </button>
            <span width="20">&nbsp;</span>
            <button
              type="button"
              className="button-wide"
              onClick={() => push('/owners')}
            >
              Owners
            </button>
            <span width="20">&nbsp;</span>
            <button
              type="button"
              className="button-wide"
              onClick={() => push('/vendors')}
            >
              Vendors
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ user }) => ({ user }))(Home);
