import React, { Component } from 'react';
import './Common.css';

// XXX
// Make into a stateless component, or use redux-form

class FormField extends Component {
  errorClass(error) {
    return error.length === 0 ? '' : '-has-error';
  }

  render() {
    const className =
      'form-myitem' +
      (this.props.formErrors ? this.errorClass(this.props.formErrors) : '');
    return (
      <div className="field-register-form">
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <input
          className={className}
          placeholder={this.props.placeholder || ''}
          name={this.props.name}
          type={this.props.type || 'text'}
          defaultValue={this.props.value}
          onChange={this.props.onChange}
          autoComplete={this.props.autoComplete}
        />
        <div className="myrectangle" />
      </div>
    );
  }
}

export default FormField;
