import React, { Component } from 'react';

import MendHeader from './MendHeader';

//import termsText from '../assets/terms.rtf';
// const termsText = '../assets/terms.rtf';

/* const RTF = ({ document }) => {
  return document ? <div className="" /> : null;
};
<RTF document={termsText} /> */

class Privacy extends Component {
  render() {
    // console.log('termsText:', termsText);
    return (
      <div className="bG">
        <MendHeader history={this.props.history} />
        <div className="content">
          <div style={{ textAlign: 'left' }}>
            This Privacy Statement explains our practices, including your
            choices, regarding the collection, use, and disclosure of certain
            information, including your personal information, by the Mendapp,
            Inc. (The Mend).
            <br />
            Contacting Us
            <br />
            If you have general questions about your account or how to contact
            Customer Service for assistance, please visit our online help center
            at https://www.themendapp.com. For questions specifically about this
            Privacy Statement, or our use of your personal information, cookies
            or similar technologies, please contact our Data Protection
            Officer/Privacy Office by email at privacy@themendapp.com.
            <br />
            Please note that if you contact us to assist you, for your safety
            and ours we may need to authenticate your identity before fulfilling
            your request.
            <br />
            Collection of Information
            <br />
            We receive and store information about you such as:
            <br />
            • Information you provide to us: We collect information you provide
            to us which includes:
            <br />
            o your name, email address, address or postal code, and telephone
            number. In certain countries, we collect a government identification
            number for purposes such as billing and tax compliance. We collect
            this information in a number of ways, including when you enter it
            while using our service, interact with our Customer Service, or
            participate in surveys or marketing promotions;
            <br />
            o information when you choose to provide reviews or ratings, taste
            preferences, account settings, (including preferences set in the
            "Account" section of our website), or otherwise provide information
            to us through our service or elsewhere.
            <br />
            • Information we collect automatically: We collect information about
            you and your use of our service, your interactions with us, as well
            as information regarding your computer or other device used to
            access our service. This information includes:
            <br />
            o your activity while using The Mend service, such as interactions
            history and search queries;
            <br />
            o your interactions with our email, push and text messaging;
            <br />
            o details of your interactions with Customer Service, such as the
            date, time and reason for contacting us, transcripts of any chat
            conversations, and if you call us, your phone number and call
            recordings;
            <br />
            o device IDs or other unique identifiers;
            <br />
            o device and software characteristics (such as type and
            configuration), connection information, statistics on page views,
            referring source (for example, referral URLs), IP address (which may
            tell us your general location), browser and standard web server log
            information;
            <br />
            Use of Information
            <br />
            We use information to provide, analyze, administer, enhance and
            personalize our services and marketing efforts, to process your
            registration, your orders and your payments, and to communicate with
            you on these and other topics. For example, we use information to:
            <br />
            • determine your general geographic location, provide localized
            content, determine your Internet service provider, and help us
            quickly and efficiently respond to inquiries and requests;
            <br />
            • prevent, detect and investigate potentially prohibited or illegal
            activities, including fraud, and enforcing our terms (such as
            determining free trial eligibility);
            <br />
            • analyze and understand our audience, improve our service
            (including our user interface experiences) and optimize qualify and
            delivery;
            <br />
            • communicate with you concerning our service (for example by email,
            push notifications text messaging, and online messaging channels),
            so that we can send you news about The Mend, details about new
            features, and special offers, promotional announcements, and
            consumer surveys, and to assist you with operational requests such
            as password reset requests. Please see the "Your Choices" section of
            this Privacy Statement to learn how to set or change your
            communications preferences.
            <br />
            Disclosure of Information
            <br />
            We disclose your information for certain purposes and to third
            parties, as described below:
            <br />
            • Service Providers: We use other companies, agents or contractors
            ("Service Providers") to perform services on our behalf or to assist
            us with the provision of services to you. For example, we engage
            Service Providers to provide marketing, advertising, communications,
            infrastructure and IT services, to personalize and optimize our
            service, to process credit card transactions or other payment
            methods, to provide customer service, to collect debts, to analyze
            and enhance data (including data about users' interactions with our
            service), and to process and administer consumer surveys. In the
            course of providing such services, these Service Providers may have
            access to your personal or other information. We do not authorize
            them to use or disclose your personal information except in
            connection with providing their services.
            <br />
            • Promotional offers: We may offer joint promotions or programs
            that, in order for your participation, will require us to share your
            information with third parties. In fulfilling these types of
            promotions, we may share your name and other information in
            connection with fulfilling the incentive. Please note that these
            third parties are responsible for their own privacy practices.
            <br />
            • Protection of The Mend and others: The Mend and its Service
            Providers may disclose and otherwise use your personal and other
            information where we or they reasonably believe such disclosure is
            needed to (a) satisfy any applicable law, regulation, legal process,
            or governmental request, (b) enforce applicable terms of use,
            including investigation of potential violations thereof, (c) detect,
            prevent, or otherwise address illegal or suspected illegal
            activities (including payment fraud), security or technical issues,
            or (d) protect against harm to the rights, property or safety of The
            Mend, its users or the public, as required or permitted by law.
            <br />
            • Business transfers: In connection with any reorganization,
            restructuring, merger or sale, or other transfer of assets, we will
            transfer information, including personal information, provided that
            the receiving party agrees to respect your personal information in a
            manner that is consistent with our Privacy Statement.
            <br />
            Whenever in the course of sharing information we transfer personal
            information to countries outside of the European Economic Area and
            other regions with comprehensive data protection laws, we will
            ensure that the information is transferred in accordance with this
            Privacy Statement and as permitted by the applicable laws on data
            protection.
            <br />
            You may also choose to disclose your information in the following
            ways:
            <br />
            • while using the The Mend service you will have opportunities to
            post reviews or other information publicly, and third parties could
            use the information you disclose;
            <br />
            • certain portions of our service may contain a tool which gives you
            the option to share information by email, text message and social or
            other sharing applications, using the clients and applications on
            your smart device;
            <br />
            • social plugins and similar technologies allow you to share
            information.
            <br />
            Social plugins and social applications are operated by the social
            networks themselves, and are subject to their terms of use and
            privacy policies.
            <br />
            Access to Account and Profiles
            <br />
            For ease of access to your account, you can use the "Remember me on
            this device" function when you log in to the website. This function
            uses technology that enables us to provide direct account access and
            to help administer the The Mend service without requiring reentry of
            any password or other user identification when your browser revisits
            the service.
            <br />
            To remove access to your The Mend account from your devices: exit
            from The Mend apps on your devices. If you sell or return a computer
            or a mobile device with The Mend software install, you should log
            out and deactivate the device before doing so. If you do not
            maintain the security of your password or device, or fail to log out
            or deactivate your device, subsequent users may be able to access
            your account, including your personal information.
            <br />
            If you share or otherwise allow others to have access to your
            account, they will be able to see your information (including in
            some cases personal information) such as your watch history,
            ratings, reviews and account information (including your email
            address or other information in the "Account" area of our website).
            This remains true even if you use our profiles feature.
            <br />
            Profiles allow users to have a distinct, personalized experience.{' '}
            <br />
            Your Choices
            <br />
            Email and Text Messages. If you no longer want to receive certain
            communications from us via email or text message, simply access the
            "Communications Settings" option in the "Account" section of our
            website and uncheck those items to unsubscribe. Please note that you
            cannot unsubscribe from service-related correspondence from us, such
            as messages relating to your account transactions.
            <br />
            Push Notifications. You can choose to receive mobile push
            notifications from The Mend. If you subsequently decide you no
            longer wish to receive these notifications, you can use your mobile
            device's settings functionality to turn them off.
            <br />
            Your Information and Rights
            <br />
            You can request access to your personal information, or correct or
            update out-of-date or inaccurate personal information we hold about
            you.
            <br />
            You can most easily do this by visiting the "Account" portion of our
            website, where you have the ability to access and update a broad
            range of information about your account, including your contact
            information, your The Mend payment information, and various related
            information about your account (such as the content you have viewed
            and rated, and your reviews). You must be signed in to access the
            "Account" section. You may also request that we delete personal
            information that we hold about you.
            <br />
            To make requests, or if you have any other question regarding our
            privacy practices, please contact our Data Protection
            Officer/Privacy Office at privacy@themendapp.com. We respond to all
            requests we receive from individuals wishing to exercise their data
            protection rights in accordance with applicable data protection
            laws. Please also see the "Your Choices" section of this Privacy
            Statement for additional choices regarding your information.
            <br />
            We may reject requests that are unreasonable or not required by law,
            including those that would be extremely impractical, could require
            disproportionate technical effort, or could expose us to operational
            risks such as free trial fraud. We may retain information as
            required or permitted by applicable laws and regulations, including
            to honor your choices, for our billing or records purposes and to
            fulfill the purposes described in this Privacy Statement. We take
            reasonable measures to destroy or de-identify personal information
            in a secure manner when it is no longer required.
            <br />
            Security
            <br />
            We use reasonable administrative, logical, physical and managerial
            measures to safeguard your personal information against loss, theft
            and unauthorized access, use and modification. These measures are
            designed to provide a level of security appropriate to the risks of
            processing your personal information.
            <br />
            Children
            <br />
            You must be 18 years of age to use The Mend service. In certain
            jurisdictions, the age of majority may be older than 18, in which
            case, you must satisfy that age in order to become a member. While
            individuals under the age of 18 may utilize the service, they may do
            so only with the involvement, supervision, and approval of a parent
            or legal guardian.
            <br />
            Changes to This Privacy Statement
            <br />
            We will update this Privacy Statement from time to time in response
            to changing legal, regulatory or operational requirements. We will
            provide notice of any such changes (including when they will take
            effect) in accordance with law. Your continued use of the The Mend
            service after any such updates take effect will constitute
            acknowledgement and (as applicable) acceptance of those changes. If
            you do not wish to acknowledge or accept any updates to this Privacy
            Statement, you may cancel your use of the The Mend service. To see
            when this Privacy Statement was last updated, please see the "Last
            Updated" section below.
            <br />
            Cookies and Internet Advertising
            <br />
            We and our Service Providers use cookies and other technologies
            (such as web beacons), as well as advertising identifiers, for
            various reasons. For example, we use these technologies to make it
            easy to access our services by remembering you when you return, to
            provide and analyze our services. We also use cookies, as well as
            advertising identifiers, to learn more about our users and their
            likely interests, and to deliver and tailor marketing or
            advertising. We want you to be informed about our use of these
            technologies, so this section explains the types of technologies we
            use, what they do and your choices regarding their use.
            <br />
            1. What are cookies?
            <br />
            Cookies are small data files that are commonly stored on your device
            when you browse and use websites and online services. They are
            widely used to make websites work, or to work more efficiently, as
            well as to provide reporting information and assist with service or
            advertising personalization.
            <br />
            Cookies are not the only types of technology that enable this
            functionality; we also use other, similar types of technologies. See
            below for more information and examples.
            <br />
            2. What are advertising identifiers?
            <br />
            Advertising identifiers are similar to cookies and are found on many
            mobile devices and tablets (for example, the "Identifier for
            Advertisers" (or IDFA) on Apple iOS devices and the "Google
            Advertising ID" on Android devices), and certain streaming media
            devices. Like cookies, advertising identifiers are used to make
            online advertising more relevant.
            <br />
            Last updated: 11 November 2018
          </div>
        </div>
      </div>
    );
  }
}

export default Privacy;
