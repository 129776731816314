import React, { Component } from 'react';
import './Common.css';
import './Confirmation.css';
import MendHeader from './MendHeader';

class ProfileUpdateConfirmation extends Component {
  handleFormSubmit(e) {
    e.preventDefault();
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="bG">
        <MendHeader history={this.props.history} />
        <div className="content">
          <div className="confirmation">
            Your profile has been successfully updated.
          </div>
          <form onSubmit={this.handleFormSubmit.bind(this)}>
            <button className="form-submit" type="submit">
              OK
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ProfileUpdateConfirmation;
