import React, { Component } from 'react';
import starOff from '../assets/star_off.png';
import starOn from '../assets/star_on.png';

class Rating extends Component {
  render() {
    const { value, alignRight } = this.props;

    let onCnt = Math.floor(value);
    if (onCnt < 0) onCnt = 0;
    if (onCnt > 5) onCnt = 5;

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <img
          src={
            alignRight
              ? i <= 5 - onCnt
                ? starOff
                : starOn
              : i <= onCnt
              ? starOn
              : starOff
          }
          alt={i <= 5 - onCnt ? '' : '*'}
          style={{ marginRight: i < 5 ? 5 : 0 }}
          key={i}
        />
      );
    }

    return <div style={{ flexDirection: 'row' }}>{stars}</div>;
  }
}

export default Rating;
